import { GET_ALL_SUB_CATEGORY, GET_ALL_VENDOR_CATEGORY } from './actionType'

export const VendorCategory = (data) => {
  return {
    type: GET_ALL_VENDOR_CATEGORY,
    payload: data,
  }
}
export const SubCategory = (data) => {
  return {
    type: GET_ALL_SUB_CATEGORY,
    payload: data,
  }
}
