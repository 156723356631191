import _ from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, Col, Row, Spinner } from 'reactstrap'
import { subCategoryList } from '../../store/actions'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'
import { UrlEncodeHook } from '../../hooks'

const SubCategoryList = ({ activeCategory }) => {
  const {  urlEncode } = UrlEncodeHook()
  const width = window.innerWidth
  const LIMIT = width <= 991 ? 8 : 9

  //
  const dispatch = useDispatch()
  ///
  const { list, loading, page, totalPage, totalRecords } = useSelector(
    (state) => {
      return {
        list: state.EasyFiedsSubCategory.subCategoryList,
        loading: state.EasyFiedsSubCategory.loading,
        page: state.EasyFiedsSubCategory.page,
        totalPage: state.EasyFiedsSubCategory.totalPage,
        totalRecords: state.EasyFiedsSubCategory.totalRecords,
      }
    }
  )
  useEffect(() => {
    if (activeCategory) {
      const data = {
        params: {
          _page: 1,
          _limit: LIMIT,
          category_id: activeCategory,
          _sort: 'sub_category_name',
          _order: 'ASC',
        },
      }
      dispatch(subCategoryList(data))
    }
  }, [activeCategory])

  const paginationClick = (nextPage) => {
    const { selected } = nextPage
    const finalPage = (Number(selected) || 0) + 1
    const data = {
      params: {
        _page: finalPage,
        _limit: LIMIT,
        category_id: activeCategory,
        _sort: 'sub_category_name',
        _order: 'ASC',
      },
    }
    dispatch(subCategoryList(data))
  }
  return (
    <Col lg='12' sm='12' md='12' xs='12' className='mb-4 '>
      <Card body className='header-easy'>
        <Row>
          {_.size(list) && !loading ? (
            list.map((cate) => {
              return (
                <Col lg='4' sm='6' md='6' xs='12' className='mb-3'>
                  <Link
                    to={`/easyfides/${urlEncode(activeCategory)}/${urlEncode(
                      cate.sponsor_vendor_sub_category_id
                    )}/listing`}
                  >
                    <Card className='w-100 h-100 border-warning '>
                      <CardBody className='d-flex flex-column flex-wrap align-items-center'>
                        <i className='bx bxs-cube-alt fs-2 text-warning mb-3'></i>
                        <h5 className='text-center w-break'>
                          {cate.sub_category_name}
                        </h5>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              )
            })
          ) : loading ? (
            <Col md='12' className='text-center'>
              <Spinner />
            </Col>
          ) : (
            <Col md='12' className='text-center'>
              <h5>No sub category currently available</h5>
            </Col>
          )}
          {!loading && totalRecords > LIMIT ? (
            <Col md='12' sm='12'>
              <ReactPaginate
                breakLabel='...'
                breakClassName='page-item'
                breakLinkClassName='page-link'
                nextLabel='>'
                pageCount={totalPage}
                pageRangeDisplayed={4}
                marginPagesDisplayed={1}
                previousLabel='<'
                renderOnZeroPageCount={null}
                containerClassName='pagination mtb-4'
                pageLinkClassName='page-link page-link'
                pageClassName='page-item'
                activeClassName='active'
                nextLinkClassName='page-link'
                previousClassName='page-item'
                previousLinkClassName='page-link'
                nextClassName='page-item'
                onPageChange={paginationClick}
                forcePage={page - 1}
              />
            </Col>
          ) : null}
        </Row>
      </Card>
    </Col>
  )
}

export default SubCategoryList
