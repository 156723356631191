import { put, takeEvery } from 'redux-saga/effects'

import {
  SUB_CATEGORY_LISTING,
  SUB_CATEGORY_LISTING_PAGINATION_DATA,
  SUB_CATEGORY_LISTING_SUCCESS,
} from './actionType'
import { toast } from 'react-toastify'

import { get } from '../../../helpers/api_helper'

function* subCategoryListingSaga({ payload }) {
  try {
    const res = yield get(`/api/v1/category/admin/sponsor-vendor/sub-category/all`, {
      params: payload?.params,
    })

    yield put({
      type: SUB_CATEGORY_LISTING_SUCCESS,
      payload: res?.data || [],
    })
    if (payload?.params?._limit) {
      const limit = payload?.params?._limit || 10
      const TotalPage = Math.ceil(res.totalRecords / limit) || 1
      const paginationObj = {
        page: payload?.params?._page,
        totalPage: TotalPage,
        totalRecords: res?.totalRecords,
      }
      yield put({
        type: SUB_CATEGORY_LISTING_PAGINATION_DATA,
        payload: paginationObj,
      })
    }
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* AnimalSagas() {
  yield takeEvery(SUB_CATEGORY_LISTING, subCategoryListingSaga)
}

export default AnimalSagas
