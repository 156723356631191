import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Row, Spinner } from 'reactstrap'
import { Link } from 'react-router-dom'
import { get } from '../../helpers/api_helper'
import _ from 'lodash'
import { UrlEncodeHook } from '../../hooks'
import { toast } from 'react-toastify'

import Banner from '../../pages/Authentication/login/Components/Banner'
import { useDispatch, useSelector } from 'react-redux'
import { categoryList } from '../../store/actions'
import Pagination from '../Profile/Pagination/Pagination'
import classNames from 'classnames'
import SubCategoryList from '../SubCategoryList/SubCategoryList'
const CategoryList = () => {
  const dispatch = useDispatch()
  const { urlEncode } = UrlEncodeHook()

  //

  const { list, loading } = useSelector((state) => {
    return {
      list: state.EasyFiedsCategory.categoryList,
      loading: state.EasyFiedsCategory.loading,
    }
  })

  //

  const [bannerList, setBannerList] = useState([])
  const [showBanner, setShowBanner] = useState(false)
  const [bannerLoading, setBannerLoading] = useState(true)

  //
  const [activeCategory, setActiveCategory] = useState(null)
  const [chunkSize, setChunkSize] = useState(3)

  const updateChunkSize = () => {
    const width = window.innerWidth
    if (width <= 576) {
      // Small screens (sm)
      setChunkSize(1) // Show 1 item per row on small screens
    } else if (width <= 768) {
      // Medium screens (md)
      setChunkSize(2) // Show 2 items per row on medium screens
    } else {
      // Large screens (lg)
      setChunkSize(3) // Show 3 items per row on large screens
    }
  }
  useEffect(() => {
    updateChunkSize() // Set initial chunk size
    window.addEventListener('resize', updateChunkSize) // Add resize event listener

    return () => {
      window.removeEventListener('resize', updateChunkSize) // Cleanup listener on unmount
    }
  }, [])

  const handleCategoryToggle = (categoryId) => {
    setActiveCategory((prev) => (prev === categoryId ? null : categoryId)) // Toggle the parent category
  }
  useEffect(() => {
    const data = {
      params: {
        _limit: 9,
        _page: 1,
        _sort: 'category_name',
        _order: 'ASC',
      },
    }
    dispatch(categoryList(data))
    const fun = async () => {
      setBannerLoading(true)
      try {
        const res = await get(
          `/api/v1/easyfied/equipment-product-doc/front-listing-all`
        )

        setBannerList(
          _.map(
            _.filter(res?.data, (f) => f.doc_type === 'img'),
            (d) => {
              return d
            }
          ) || []
        )
        if (_.size(_.filter(res?.data, (f) => f.doc_type === 'img'))) {
          setShowBanner(true)
        }
        setBannerLoading(false)
      } catch (err) {
        setBannerList([])
        setShowBanner(false)
        setBannerLoading(false)
        if (err) {
          if (Array.isArray(err.response.data)) {
            toast.error(
              _.join(
                _.map(err.response.data, (_e) => _e.msg),
                ','
              )
            )
          }
        } else if (err.response.data) {
          toast.error(err.response.data)
        }
      }
    }
    fun()
  }, [])

  const paginationClick = (nextPage) => {
    const { selected } = nextPage
    const finalPage = (Number(selected) || 0) + 1
    const data = {
      params: {
        _limit: 9,
        _page: finalPage,
        _sort: 'category_name',
        _order: 'ASC',
      },
    }
    // setActiveCollapse('')
    dispatch(categoryList(data))
  }
  return (
    <section className='section bg-gray-101 pages event-list mt-12 easy-banner'>
      <Container fluid style={{ maxWidth: '95%' }}>
        <div className='row mb-6'>
          <div className='col-md-12 col-lg-12 col-xs-12 mb-3'>
            <marquee behavior='scroll' scrollamount='6' direction='left'>
              <h4 className='text-danger fw-500'>
                <strong>
                  {' '}
                  <em className='pr-5'>Easyfieds</em>
                </strong>{' '}
                is available to view by all Easy2Show users and Premier
                Exhibitors can post animals/products/services for sale. Please
                refer to the How 2's for more information on how to use{' '}
                <strong>
                  {' '}
                  <em>Easyfieds.</em>
                </strong>
              </h4>
            </marquee>
          </div>
          <div className='col-md-12 col-lg-12 col-xs-12 text-center'>
            <h2 className='top-title'>
              <span>Listings</span>
            </h2>
          </div>
        </div>
        <Row>
          <Col md='12' lg='6' xl='6' sm='12'>
            <Row>
              {_.chunk(CategoryLists, chunkSize).map((catChunk) => (
                <>
                  {catChunk.map((category) => {
                    return (
                      <React.Fragment key={category.sponsor_vendor_category_id}>
                        {/* Parent Category Card */}
                        <Col lg='4' sm='6' md='6' xs='12' className='mb-4'>
                          <Card className='w-100 h-100 border-primary'>
                            <CardBody className='d-flex flex-column flex-wrap align-items-center'>
                              <i className='bx bxs-cube-alt fs-2 text-primary mb-3'></i>
                              <h5 className='text-center w-break'>
                                {category.category_name}
                              </h5>
                              <i
                                className='fa fa-chevron-circle-down fs-4 cursor-pointer text-warning mt-2'
                                onClick={() =>
                                  handleCategoryToggle(
                                    category.sponsor_vendor_category_id
                                  )
                                } // Toggle parent category
                              ></i>
                            </CardBody>
                          </Card>
                        </Col>

                        {/* Check if the parent category is expanded */}
                      </React.Fragment>
                    )
                  })}
                  {catChunk.map((category) => {
                    return (
                      activeCategory ===
                        category.sponsor_vendor_category_id && (
                        <Col lg='12' sm='12' md='12' xs='12' className='mb-4'>
                          <Row>
                            {/* Subcategories List */}
                            {category.subCategories.length > 0 ? (
                              category.subCategories.map(
                                (subCategory, index) => (
                                  <Col
                                    lg='4'
                                    sm='6'
                                    md='6'
                                    xs='12'
                                    key={index}
                                    className='mb-4'
                                  >
                                    <Card className='w-100 h-100 border-warning'>
                                      <CardBody>
                                        <h6>{subCategory.sub_category_name}</h6>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                )
                              )
                            ) : (
                              <Col
                                lg='12'
                                sm='12'
                                md='12'
                                xs='12'
                                className='mb-4'
                              >
                                No subcategories available
                              </Col>
                            )}
                          </Row>
                        </Col>
                      )
                    )
                  })}
                </>
              ))}

              <Col lg={12}>
                <Pagination paginationClick={paginationClick} limit={9} />
              </Col>
            </Row>
          </Col>

          <Col
            md='12'
            lg='6'
            xl='6'
            sm='12'
            className={classNames({
              'd-flex justify-content-center align-items-center':
                !bannerLoading && !showBanner && !_.size(bannerList),
            })}
          >
            {!bannerLoading && showBanner && _.size(bannerList) ? (
              <Banner list={bannerList} />
            ) : !bannerLoading && !showBanner && !_.size(bannerList) ? (
              <img
                src={`${process.env.PUBLIC_URL}/assets/img/user-login.png`}
                alt='banner-logo'
                className='logo-max-view'
              />
            ) : null}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default CategoryList
const CategoryLists = [
  {
    sponsor_vendor_category_id: 1,
    category_name: 'Livestock',
    subCategories: [
      { sub_category_name: 'Cattle' },
      { sub_category_name: 'Sheep' },
      { sub_category_name: 'Goats' },
    ],
  },
  {
    sponsor_vendor_category_id: 2,
    category_name: 'Equipment / Supplies',
    subCategories: [
      { sub_category_name: 'Fencing' },
      { sub_category_name: 'Feeders' },
      { sub_category_name: 'Water Troughs' },
    ],
  },
  {
    sponsor_vendor_category_id: 3,
    category_name: 'Judges / Registrars',
    subCategories: [
      { sub_category_name: 'Judging Services' },
      { sub_category_name: 'Registrars' },
    ],
  },
  {
    sponsor_vendor_category_id: 4,
    category_name: 'Breeders Listing',
    subCategories: [
      { sub_category_name: 'Poultry Breeders' },
      { sub_category_name: 'Dog Breeders' },
    ],
  },
  {
    sponsor_vendor_category_id: 5,
    category_name: 'Clubs / Show Circuits',
    subCategories: [
      { sub_category_name: 'Horse Shows' },
      { sub_category_name: 'Dog Shows' },
    ],
  },
  {
    sponsor_vendor_category_id: 6,
    category_name: 'Other',
    subCategories: [],
  },
]
const loading = false
