import { PRODUCT_LISTING, PRODUCT_LISTING_SUCCESS } from './actionType'

//

const INIT_STATE = {
  productList: [],
  loading: true,
  error: {},
}

const EasyFiedsProductReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case PRODUCT_LISTING:
      return {
        ...state,
        productList: [],
        loading: true,
      }
    case PRODUCT_LISTING_SUCCESS:
      return {
        ...state,
        productList: payload,
        loading: false,
      }

    default:
      return state
  }
}

export default EasyFiedsProductReducer
