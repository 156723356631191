import { put, takeEvery } from 'redux-saga/effects'

import { LISTING_CATEGORY, LISTING_CATEGORY_SUCCESS } from './actionType'
import { toast } from 'react-toastify'

import { PaGINATION_DATA } from '../../pagination/actionTypes'
import { get } from '../../../helpers/api_helper'

function* categoryListingSaga({ payload }) {
  try {
    const res = yield get(`/api/v1/category/admin/sponsor-vendor/all`, {
      params: payload?.params,
    })

    yield put({
      type: LISTING_CATEGORY_SUCCESS,
      payload: res?.data || [],
    })
    if (payload?.params?._limit) {
      const limit = payload?.params?._limit || 10
      const TotalPage = Math.ceil(res.totalRecords / limit) || 1
      const paginationObj = {
        parPage: payload?.params?._limit || 10,
        page: payload?.params?._page,
        totalPage: TotalPage,
        totalRecords: res?.totalRecords,
      }
      yield put({
        type: PaGINATION_DATA,
        payload: paginationObj,
      })
    }
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* AnimalSagas() {
  yield takeEvery(LISTING_CATEGORY, categoryListingSaga)
}

export default AnimalSagas
