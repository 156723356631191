import React, { useEffect, useState } from 'react'
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import * as validation from '../../../helpers/validation'
import * as Util from '../../../helpers/utils'
import { useDispatch, useSelector } from 'react-redux'
import {
  SubCategory,
  VendorCategory,
  addEquipment,
  updateEquipment,
} from '../../../store/actions'
import { useHistory, useParams } from 'react-router'
import { UrlEncodeHook } from '../../../hooks'
import { get } from '../../../helpers/api_helper'
const EquipmentForm = () => {
  const { urlDecode } = UrlEncodeHook()
  let { ID } = useParams()
  const history = useHistory()
  ID = urlDecode(ID)
  const dispatch = useDispatch()
  const { categoryList, subCategoryList } = useSelector((state) => state.VendorCategory)
  const [type, setType] = useState('new')
  const [category_id, setCategory_id] = useState('')
  const [subCategoryId, setSubCategoryId] = useState()
  const [amount, setAmount] = useState('')
  const [description, setDescription] = useState('')
  const [product_name, setproduct_name] = useState('')
  const [error, setError] = useState({})
  useEffect(() => {
    dispatch(VendorCategory())
  }, [])
  useEffect(() => {
    if (category_id) {
      const params = {
        category_id: category_id,
      }
      dispatch(SubCategory(params))
    }
  }, [category_id])
  useEffect(() => {
    if (ID) {
      get(`/api/v1/easyfied/equipment-product/one/${ID}`).then((res) => {
        let finalData = res.data
        setCategory_id(finalData?.category_id)
        setSubCategoryId(finalData?.sub_category_id || '')
        setType(finalData?.equipment_product_type)
        setproduct_name(finalData?.product_name)
        setDescription(finalData?.text_description)
        setAmount(finalData?.amount)
      })
    }
  }, [ID])
  const onSubmit = () => {
    let validationFlag = false
    let err = {}
    if (!category_id) {
      validationFlag = true
      err.category_id = `Category ${validation.REQUIRED}`
    }
    if (!subCategoryId) {
      validationFlag = true
      err.sub_category_id = `Sub Category ${validation.REQUIRED}`
    }
    if (!amount.trim()) {
      validationFlag = true
      err.amount = `Amount ${validation.REQUIRED}`
    }
    if (amount.trim()) {
      if (!Util.PRICE_REGEX.test(amount)) {
        validationFlag = true
        err.amount = `Amount ${validation.INVALID}`
      } else if (Number(amount) > 999) {
        validationFlag = true
        err.amount = `Amount ${validation.MAX_NUMBER(999)}`
      }
    }
    if (!description.trim()) {
      validationFlag = true
      err.description = `Description ${validation.REQUIRED}`
    }
    if (description.trim()) {
      if (description.trim().length >= 2000) {
        validationFlag = true
        err.description = `Description ${validation.MAX_CHAR_FUN(20000)}`
      }
    }
    if (!product_name.trim()) {
      validationFlag = true
      err.product_name = `Product name ${validation.REQUIRED}`
    }
    if (product_name.trim()) {
      if (product_name.trim().length >= 100) {
        validationFlag = true
        err.product_name = `Product name ${validation.MAX_CHAR_FUN(100)}`
      } else if (!Util.ONLY_ALLOW_NUMBER_AND_CHAR.test(product_name.trim())) {
        validationFlag = true
        err.product_name = `${validation.INVALID_DATA}`
      }
    }
    setError(err)
    if (!validationFlag) {
      document.getElementById('submit-btn').setAttribute('disabled', true)
      let data = {
        equipment_from: 'nonvendor',
        product_from:'my_listing',
        equipment_product_type: type,
        category_id: category_id,
        sub_category_id: subCategoryId,
        social_link: null,
        product_name: product_name.trim(),
        text_description: description.trim(),
        business_name: null,
        amount: amount.trim(),
      }
      let obj = {
        ID,
        data: data,
        history,
        path:'/easyfides/profile/my-listings'
      }
      if (!ID) {
        dispatch(addEquipment(obj))
      } else {
        dispatch(updateEquipment(obj))
      }
    }
  }
  return (
    <div className='profile-content-area my-6 card card-body mb-10'>
      <div className='pb-6 easyfied-form'>
        <Row>
          <Col md={12} sm={12} lg={12}>
            <h4 className='mb-3'>Selling new or used equipment (Non Vendor)</h4>
          </Col>
          <Label>Type * </Label>
          <FormGroup className='mb-3' onChange={(e) => setType(e.target.value)}>
            <Input
              type='radio'
              value='new'
              name='type'
              id='new'
              checked={type === 'new' ? true : false}
            />{' '}
            <Label check htmlFor='new'>
              New
            </Label>
            <Input
              type='radio'
              value='used'
              name='type'
              id='used'
              checked={type === 'used' ? true : false}
            />{' '}
            <Label check htmlFor='used'>
              Used
            </Label>
          </FormGroup>
          <FormGroup className='my-3'>
            <Label>Category *</Label>
            <select
              name='category_id'
              className='form-control form-select'
              onChange={(e) => {
                setSubCategoryId('')
                setCategory_id(e.target.value)
              }}
              value={category_id}
            >
              <option value=''>Select Category</option>

              {Array.isArray(categoryList) && categoryList.length
                ? categoryList.map((category, i) => {
                    return (
                      <option
                        value={category?.sponsor_vendor_category_id || ''}
                        key={i}
                      >
                        {category?.category_name || ''}
                      </option>
                    )
                  })
                : null}
            </select>
            {error?.category_id ? (
              <div className='text-danger mt-1'>{error?.category_id}</div>
            ) : null}
          </FormGroup>
          <FormGroup className='my-3'>
            <Label>Sub Category *</Label>
            <select
              name='sub_category_id'
              className='form-control form-select'
              onChange={(e) => {
                setSubCategoryId(e.target.value)
              }}
              value={subCategoryId}
            >
              <option value=''>Select Sub Category</option>

              {Array.isArray(subCategoryList) && subCategoryList.length
                ? subCategoryList.map((category, i) => {
                    return (
                      <option
                        value={category?.sponsor_vendor_sub_category_id || ''}
                        key={i}
                      >
                        {category?.sub_category_name || ''}
                      </option>
                    )
                  })
                : null}
            </select>
            {error?.sub_category_id ? (
              <div className='text-danger mt-1'>{error?.sub_category_id}</div>
            ) : null}
          </FormGroup>

          <FormGroup className='my-3'>
            <Label htmlFor='Product Name' className='d-block'>
              Product Name *
            </Label>
            <Input
              id='Product Name'
              name='Product Name'
              placeholder='Product Name'
              type='text'
              onChange={(e) => setproduct_name(e.target.value)}
              value={product_name}
            />
            {error?.product_name ? (
              <div className='text-danger mt-1'>{error?.product_name}</div>
            ) : null}
          </FormGroup>

          <FormGroup className='my-3'>
            <Label htmlFor='amount'>Amount *</Label>
            <Input
              id='amount'
              name='amount'
              placeholder='Amount'
              type='text'
              onChange={(e) => setAmount(e.target.value)}
              value={amount}
            />
            {error?.amount ? (
              <div className='text-danger mt-1'>{error?.amount}</div>
            ) : null}
          </FormGroup>
          <FormGroup className='my-3'>
            <Label htmlFor='Description' className='d-block'>
              Description *
            </Label>
            <Input
              id='Description'
              name='Description'
              placeholder='Description'
              type='textarea'
              rows='4'
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
            {error?.description ? (
              <div className='text-danger mt-1'>{error?.description}</div>
            ) : null}
          </FormGroup>

          <div className='p-20px-t mt-6'>
            <Button
              type='submit'
              name='btnSubmit'
              className='w-lg waves-effect waves-light btn btn-success'
              onClick={onSubmit}
              title='Submit'
              id='submit-btn'
            >
              SUBMIT
            </Button>
          </div>
        </Row>
      </div>
    </div>
  )
}

export default EquipmentForm
