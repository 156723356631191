import {
  SUB_CATEGORY_LISTING,
  SUB_CATEGORY_LISTING_SUCCESS,
  SUB_CATEGORY_LISTING_PAGINATION_DATA,
} from './actionType'

//

const INIT_STATE = {
  subCategoryList: [],
  loading: true,
  page: 1,
  totalPage: 1,
  totalRecords: 0,
  error: {},
}

const EasyFiedsSubCategoryList = (state = INIT_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case SUB_CATEGORY_LISTING:
      return {
        ...state,
        subCategoryList: [],
        loading: true,
      }
    case SUB_CATEGORY_LISTING_SUCCESS:
      return {
        ...state,
        subCategoryList: payload,
        loading: false,
      }

    case SUB_CATEGORY_LISTING_PAGINATION_DATA:
      return {
        ...state,
        page: payload?.page || 1,
        totalPage: payload?.totalPage || 1,
        totalRecords: payload?.totalRecords || 0,
      }
    default:
      return state
  }
}

export default EasyFiedsSubCategoryList
