import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { Row, Col, Container, Label } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { post } from '../../../helpers/api_helper'

const IntegratedPlan = () => {
  const history = useHistory()
  const location = useLocation()

  let info = localStorage.getItem('purchaseInfo')
  if (info) info = JSON.parse(info)
  const query = new URLSearchParams(location.search)
  const secretaryId = query.get('secretary')

  const [open, setOpen] = useState(0)
  const [youth, setYouth] = useState(0)
  const [error_shows, setErrorShows] = useState('')

  useEffect(() => {
    if (info) {
      setOpen(parseInt(info.open) || 0)
      setYouth(parseInt(info.youth) || 0)
    }

    if (secretaryId) {
      post(`/api/v1/plan-purchase/get-secretary-details-for-plan-purchase`, {
        unique_string: secretaryId,
      })
        .then((data) => {
          if (data === 'Something went wrong.') {
            toast.error(data)
          }
          if (data) {
            let userInfo = {
              user_role: 'secretary',
              first_name: data.first_name.trim(),
              last_name: data.last_name.trim(),
              email: data.email,
              phone: data.phone,
              plan_type: 'integrated',
              club_list: [],
              open: 0,
              youth: 0,
            }

            localStorage.setItem('purchaseInfo', JSON.stringify(userInfo))
          }
        })
        .catch((error) => {
          toast.error('Something went wrong!')
          console.log(error)
        })
    }
  }, [secretaryId])


  // Redirect integrate plan if page is reloaded 
  if (!info && !secretaryId) {
    return <Redirect to='/integrated_plan' />
  }

  const onSelectShows = (e) => {
    let errShows = ''
    if (open === 0 && youth === 0) {
      errShows = 'Please select atleast any one show'
    }

    if (errShows) {
      setErrorShows(errShows)
    } else {
      setErrorShows('')
      info.open = open && parseInt(open)
      info.youth = youth && parseInt(youth)
      info.plan_type = 'integrated'
      localStorage.setItem('purchaseInfo', JSON.stringify(info))
      history.push({
        pathname: '/plan_shopping_cart',
      })
    }
  }

  return (
    <React.Fragment>
      <div className='page-content bg-gray-101 min-h-80'>
        <section id='about' class='section1 bg-gray-101 pages event-list'>
          <Container fluid={true} className='auto-fit'>
            <Row>
              <Col lg={12} className='mt-5 text-center'>
                <h2 className='top-title-plan mb-4'>
                  <span>Plan Purchase</span>
                </h2>
              </Col>
            </Row>

            <Container>
              <Row>
                <Col xl={12} className='plan-structure mt-5'>
                  <div className='page-title-box justify-content-between'>
                    <h2 className='mt-4 top-title mb-4 text-center'>
                      <span>INTEGRATED PLAN</span>
                    </h2>
                  </div>

                  <Col lg={7} className='mb-3 m-auto'>
                    <Row>
                      <Col lg={6}>
                        <div className='plan-input'>
                          <Label className='form-label' htmlFor=''>
                            Open Shows
                          </Label>
                          <select
                            type='text'
                            name='licenses'
                            id='licenses'
                            className={classNames('form-control  form-select', {
                              'is-invalid': error_shows.length,
                            })}
                            onChange={(e) => setOpen(parseInt(e.target.value))}
                            value={open}
                          >
                            <option value={0}>Select Open Shows</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                          </select>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className='plan-input'>
                          <Label className='form-label' htmlFor=''>
                            Youth Shows
                          </Label>
                          <select
                            type='text'
                            name='licenses'
                            id='licenses'
                            className={classNames('form-control form-select', {
                              'is-invalid': error_shows.length,
                            })}
                            onChange={(e) => setYouth(parseInt(e.target.value))}
                            value={youth}
                          >
                            <option value={0}>Select Youth Shows</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                          </select>
                        </div>
                      </Col>
                      <div className='text-danger'>{error_shows}</div>
                    </Row>

                    <div className='plan-btn text-center mt-5 col-lg-12'>
                      {!Boolean(secretaryId) ? (
                        <Link to='/integrated_plan'>
                          <button
                            type='button'
                            className='btn btn-primary btn-back w-lg mb-3'
                          >
                            BACK
                          </button>
                        </Link>
                      ) : null}

                      <button
                        type='button'
                        className='btn btn-primary btn-easy w-lg mb-3'
                        onClick={(e) => onSelectShows(e)}
                      >
                        CONTINUE
                      </button>
                    </div>
                  </Col>
                </Col>
              </Row>
            </Container>
          </Container>
        </section>
      </div>
    </React.Fragment>
  )
}

export default IntegratedPlan
