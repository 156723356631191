import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import Animal1 from '../dummyImage/animal-4137865_1920.jpg'
import Animal2 from '../dummyImage/easter-1236515_1920.jpg'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import _ from 'lodash'
import { get } from '../../../../helpers/api_helper'
import { toast } from 'react-toastify'
const items = [
  {
    id: 1,
    src: Animal1,
    altText: 'Slide 1',
    caption: 'Slide 1',
    heading: 'Pamper Your Bunny with the Best',
    description: 'Soft, Safe & Snuggly Comfort – Designed Just for Rabbits!',
  },
  {
    id: 2,
    src: Animal2,
    altText: 'Slide 2',
    caption: 'Slide 2',
    heading: 'Hop Into Comfort!',
    description: 'Premium Rabbit Essentials for Happy, Healthy Bunnies!',
  },
]

function NextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className='custom-arrow next' onClick={onClick}>
      <i className='bx bx-chevron-right'></i>
    </div>
    // <button
    //   className={`${className}`}
    //   style={{
    //     ...style,

    //     width: '30px',
    //     height: '100px',
    //     borderRadius: '5px',
    //   }}
    //   onClick={onClick}
    // />
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className='custom-arrow prev' onClick={onClick}>
      <i className='bx bx-chevron-left'></i>
    </div>
    // <button
    //   className={`${className}`}
    //   style={{
    //     ...style,
    //     width: '30px',
    //     height: '100px',
    //     borderRadius: '5px',
    //   }}
    //   onClick={onClick}
    // />
  )
}

const Banner = ({ list }) => {
  const settings = {
    dots: false,
    infinite: true,
    lazyLoad: true,
    fade: true,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    className: 'myCustomCarousel',
  }

  return (
    <div className='slider-container'>
      <Slider {...settings}>
        {Array.isArray(list) && _.size(list)
          ? list.map((item, index) => {
              return (
                <div className='slide'>
                  <div key={item.id} className='image-container'>
                    <img
                      src={item.document_file}
                      alt={item.altText}
                      className='rounded-4 slide-image'
                    />
                  </div>
                </div>
              )
            })
          : null}
      </Slider>
    </div>
  )
}

export default Banner
