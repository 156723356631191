import { put, takeEvery } from 'redux-saga/effects'

import { PRODUCT_LISTING, PRODUCT_LISTING_SUCCESS } from './actionType'
import { post, get, del, PUT, PATCH } from '../../../helpers/api_helper'
import { toast } from 'react-toastify'

import { PaGINATION_DATA } from '../../pagination/actionTypes'

function* productListingSagas({ payload }) {
  try {
    /**
     * 
     * _page=${
        payload?._page || ''
      }&_limit=${payload?._limit || ''}&_sort=${payload?._sort || ''}&_order=${
        payload?._order || ''
      }&product_name=${payload?.product_name || ''}&equipment_from=${
        payload?.equipment_from || ''
      }&equipment_product_type=${
        payload?.equipment_product_type || ''
      }&category_id=${payload?.category_id || ''}
     */
    const res = yield get(`/api/v1/easyfied/equipment-product/all-listing`, {
      params: payload.params,
    })

    yield put({
      type: PRODUCT_LISTING_SUCCESS,
      payload: res?.data || [],
    })
    if (payload?.params?._limit) {
      const limit = payload?.params?._limit || 10
      const TotalPage = Math.ceil(res.totalRecords / limit) || 1
      const paginationObj = {
        page: payload.params._page,
        totalPage: TotalPage,
        totalRecords: res?.totalRecords,
      }
      yield put({
        type: PaGINATION_DATA,
        payload: paginationObj,
      })
    }
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* EquipmentSaga() {
  yield takeEvery(PRODUCT_LISTING, productListingSagas)
}

export default EquipmentSaga
