import { useCallback, useState } from 'react'
import { Col, Button } from 'reactstrap'
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import 'react-rangeslider/lib/index.css'

import getCroppedImg, { dataURLtoFile } from '../../helpers/cropImage'

const ImageCopper = ({
  selectedFiles,
  setCroppedImage,
  setProfile,
  croppedImage,
  aspect = 4 / 3,
  FileObj,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        selectedFiles,
        croppedAreaPixels,
        rotation,
        FileObj?.type || 'image/jpeg'
      )
      //
      let date = new Date().getTime()

      let file = dataURLtoFile(croppedImage, `easy2show-${date}.png`)
      setProfile(file)
      setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation])

  return (
    <>
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: 200,
          background: '#333',
        }}
      >
        <Cropper
          image={selectedFiles}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          // cropSize={cropSize}
          showGrid
          aspect={aspect}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <Col xl={12} className='h-100'>
        <Slider
          className='mt-4'
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby='Zoom'
          onChange={(value, data) => {
            setZoom(data)
          }}
          // onChange={(e, zValue) => setZoom(zValue)}
        />
        <Button
          onClick={showCroppedImage}
          className='btn btn-primary mt-4'
          color='primary'
          type='button'
        >
          Save
        </Button>
      </Col>
      <Col md='12' className='mt-4'>
        {croppedImage ? (
          <img
            src={croppedImage}
            alt='preview-img'
            style={{
              width: 200,
              height: 150,
            }}
          />
        ) : null}
      </Col>
    </>
  )
}

export default ImageCopper
