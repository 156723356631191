import { put, takeEvery } from 'redux-saga/effects'

import {
  ADD_MY_ANIMALS,
  ALL_MEDIA_LIST,
  ALL_MEDIA_LIST_SUCCESS,
  DELETE_ANIMAL,
  DELETE_MEDIA,
  DELETE_MEDIA_SUCCESS,
  LISTING_MY_ANIMALS,
  LISTING_MY_ANIMALS_SUCCESS,
  SAVE_MEDIA,
  UPDATE_MEDIA,
  UPDATE_MY_ANIMALS,
} from './actionType'
import { post, get, del, PUT, PATCH } from '../../../helpers/api_helper'
import { toast } from 'react-toastify'

import { PaGINATION_DATA } from '../../pagination/actionTypes'

function* addAnimalSagas({ payload }) {
  try {
    const res = yield post(
      `/api/v1/easyfied/animal-listing/user/create`,
      payload?.data
    )
    if (res) {
      toast.success(res)
    }
    setTimeout(() => {
      payload.history.push('/easyfides/profile/my-listings')
    }, 1500)
  } catch (err) {
    document.getElementById('submit-btn').removeAttribute('disabled')
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* AnimalListingSaga({ payload }) {
  try {
    const res = yield get(
      `/api/v1/easyfied/animal-listing/user/all?_page=${
        payload?._page || ''
      }&_limit=${payload?._limit || ''}&_sort=${payload?._sort || ''}&_order=${
        payload?._order || ''
      }&category_id=${payload?.category_id || ''}&breed_id=${
        payload?.breed_id || ''
      }&class_type_id=${payload?.class_type_id || ''}&all_user_data=${
        payload?.all_user_data || ''
      }&state_id=${payload?.state_id || ''}&city=${payload?.city || ''}`
    )

    yield put({
      type: LISTING_MY_ANIMALS_SUCCESS,
      payload: res?.data || [],
    })
    if (payload?._limit) {
      const limit = payload._limit || 10
      const TotalPage = Math.ceil(res.totalRecords / limit) || 1
      const paginationObj = {
        page: payload._page,
        totalPage: TotalPage,
        totalRecords: res?.totalRecords,
      }
      yield put({
        type: PaGINATION_DATA,
        payload: paginationObj,
      })
    }
  } catch (err) {
    if (err?.response?.data) {
      document.getElementById('submit-btn').removeAttribute('disabled')
      toast.error(err?.response?.data)
    }
  }
}

function* AnimalDeleteSaga({ payload }) {
  try {
    const res = yield del(
      `/api/v1/easyfied/animal-listing/user/delete/${payload?.ID}`
    )
    if (res) {
      toast.success(res)
      payload.setReload(!payload.reload)
    }
  } catch (err) {
    if (err?.response?.data) {
      document.getElementById('submit-btn').removeAttribute('disabled')
      toast.error(err?.response?.data)
    }
  }
}

function* AnimalUpdateSaga({ payload }) {
  try {
    const res = yield PUT(
      `/api/v1/easyfied/animal-listing/user/update/${payload?.ID}`,
      payload?.data
    )
    if (res) {
      toast.success(res)
    }
    setTimeout(() => {
      payload.history.push('/easyfides/profile/my-listings')
    }, 1500)
  } catch (err) {
    if (err?.response?.data) {
      document.getElementById('submit-btn').removeAttribute('disabled')
      toast.error(err?.response?.data)
    }
  }
}

function* saveMediaSaga({ payload }) {
  try {
    const res = yield post(
      `/api/v1/easyfied/animal-listing-doc/user/create?animal_listing_id=${payload?.params?.animal_listing_id}`,
      payload?.data
    )
    if (res.data) {
      payload.setReload(!payload.reload)
      toast.success(res.data)
    }
    document
      .getElementById(`submit-btn-${payload?.index}`)
      .removeAttribute('disabled')
  } catch (err) {
    if (err?.response?.data) {
      document
        .getElementById(`submit-btn-${payload?.index}`)
        .removeAttribute('disabled')
      toast.error(err?.response?.data)
    }
  }
}
function* updateMediaSaga({ payload }) {
  try {
    const res = yield PATCH(
      `api/v1/easyfied/animal-listing-doc/user/update/${payload?.params?.ID}?animal_listing_id=${payload?.params?.animal_listing_id}`,
      payload?.data
    )
    if (res.data) {
      payload.setReload(!payload.reload)
      toast.success(res.data)
      document
        .getElementById(`submit-btn-${payload?.index}`)
        .removeAttribute('disabled')
    }
  } catch (err) {
    if (err?.response?.data) {
      document
        .getElementById(`submit-btn-${payload?.index}`)
        .removeAttribute('disabled')
      toast.error(err?.response?.data)
    }
  }
}
function* allAnimalMediaSaga({ payload }) {
  try {
    const res = yield get(
      `/api/v1/easyfied/animal-listing-doc/user/all?animal_listing_id=${payload?.params?.animal_listing_id}`
    )

    yield put({
      type: ALL_MEDIA_LIST_SUCCESS,
      payload: res?.data || [],
    })
  } catch (err) {
    if (err?.response?.data) {
      document.getElementById('submit-btn').removeAttribute('disabled')
      toast.error(err?.response?.data)
    }
  }
}

function* deleteAnimalMediaSagas({ payload }) {
  try {
    if (payload?.ID) {
      const res = yield del(
        `/api/v1/easyfied/animal-listing-doc/user/delete/${payload?.ID}`,
        payload?.data
      )
      if (res.data) {
        toast.success(res.data)
      }
    }

    yield put({
      type: DELETE_MEDIA_SUCCESS,
      payload,
    })
  } catch (err) {
    document.getElementById('submit-btn').removeAttribute('disabled')
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* AnimalSagas() {
  yield takeEvery(ADD_MY_ANIMALS, addAnimalSagas)

  yield takeEvery(LISTING_MY_ANIMALS, AnimalListingSaga)
  yield takeEvery(DELETE_ANIMAL, AnimalDeleteSaga)
  yield takeEvery(UPDATE_MY_ANIMALS, AnimalUpdateSaga)
  yield takeEvery(SAVE_MEDIA, saveMediaSaga)
  yield takeEvery(UPDATE_MEDIA, updateMediaSaga)
  yield takeEvery(DELETE_MEDIA, deleteAnimalMediaSagas)
  yield takeEvery(ALL_MEDIA_LIST, allAnimalMediaSaga)
}

export default AnimalSagas
