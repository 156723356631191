import React, { useEffect, useState } from 'react'
import { CardBody, Col, Modal, Row, Spinner } from 'reactstrap'
import {
  Button,
  Card,
  CardHeader,
  ModalBody,
  ModalHeader,
  Table,
  TextField,
} from '../Atoms'
import Breadcrumb from '../../../common/Breadcrumb'
import { useDispatch, useSelector } from 'react-redux'
import { ancestorList, updateRabbit } from '../../../store/actions'
import _ from 'lodash'
import Pagination from '../../../HerdManagement/Pagination/Pagination'
import RabbitForm from './RabbitForm/RabbitForm'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { Field, Form, Formik, useFormikContext } from 'formik'
import { nextPage } from '../../../store/HerdManagement/Pagination/action'
const LIMIT = 2
// const LIMIT = process.env.REACT_APP_LIMIT
const AncestralArchive = () => {
  const { AncestralArchive, AncestralArchiveLoading, page, totalRecords } =
    useSelector((state) => ({
      AncestralArchive:
        state.herdManagement.AncestralArchive.AncestralArchive.list,
      AncestralArchiveLoading:
        state.herdManagement.AncestralArchive.AncestralArchive.loading,
      page: state?.herdManagement?.pagination?.page || 1,
      totalRecords: state?.herdManagement?.pagination?.totalRecords,
    }))
  const [animalForm, setAnimalForm] = useState()
  const [showModal, setShowModal] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [isReloadComponentCall, setIsReloadComponentCall] = useState(false)
  const [reload, setReload] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    // const data = {
    //   params: {
    //     _page: 1,
    //     _limit: LIMIT,
    //     _sort: 'breed_name',
    //     _order: 'ASC',
    //   },
    // }
    // dispatch(ancestorList(data))
  }, [])

  const paginationClick = (p) => {
    const { selected } = p
    const finalPage = (Number(selected) || 0) + 1

    const res = dispatch(nextPage(finalPage))
    if (res) {
      setReload(!reload)
      setIsReloadComponentCall(true)
      // setIsDelete(false)
    }
  }

  const onCloseModal = () => {
    // const data = {
    //   params: {
    //     _page: page,
    //     _limit: LIMIT,
    // _sort: 'animal_name',
    // _order: 'ASC',
    //   },
    // }

    // dispatch(ancestorList(data))
    setShowModal(false)
    setAnimalForm('')
  }
  const updateRabbitApi = (body) => {
    return new Promise((resolve, reject) => {
      dispatch(
        updateRabbit(body, (err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      )
    })
  }

  const deleteAncestral = (ID) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      width: '380px',
      showCancelButton: true,
      confirmButtonColor: '#062575',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const formData = new FormData()
          formData.append('is_deactivated', 1)
          const data = {
            params: {
              rabbit_id: ID,
            },
            data: formData,
          }
          const res = await updateRabbitApi(data)

          if (res?.data) {
            setReload(!reload)
            setIsReloadComponentCall(true)
            setIsDelete(true)
            toast.success(res?.data)
          }
        } catch (err) {
          if (Array.isArray(err)) {
            toast.error(_.get(_.first(err), 'msg'))
          } else if (err) {
            toast.error(err)
          }
        }
      }
    })
  }
  return (
    <Card className='layout'>
      <Row>
        <CardHeader>
          <Row>
            <Breadcrumb title='Ancestral Archive' />
          </Row>
        </CardHeader>
        <CardBody>
          <Col md='12' className='mb-3 text-center'>
            <h4>
              Total Animals:{' '}
              <strong>{!AncestralArchiveLoading ? totalRecords : 0}</strong>
            </h4>
          </Col>
          <Col md='3' className='mb-3'>
            <Formik initialValues={{ search: '' }}>
              {(formProps) => (
                <Form className='needs-validation'>
                  <SearchComponent />
                  {isReloadComponentCall ? (
                    <ReloadComponent reload={reload} isDelete={isDelete} />
                  ) : null}
                  <Row>
                    <Col md='12' lg='12' sm='12'>
                      <Field
                        name='search'
                        placeholder='Search By Breed Name'
                        type='text'
                        id='search'
                        component={TextField}
                      />
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
          <Col md={12}>
            <Table className='table-responsive'>
              <thead>
                <tr>
                  <th className='w-1'>No.</th>
                  <th>Breed Name</th>
                  <th>Ear/Tag #</th>
                  <th>Animal Name</th>
                  <th>Gender</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!AncestralArchiveLoading && _.size(AncestralArchive) ? (
                  AncestralArchive.map((ancestral, i) => {
                    return (
                      <tr key={i}>
                        <td className='w-1'>{LIMIT * (page - 1) + i + 1}</td>
                        <td>{ancestral?.breed_name || ''}</td>
                        <td>{ancestral?.ear_no || ''}</td>
                        <td>{ancestral?.animal_name || ''}</td>
                        <td>{ancestral?.gender === 'M' ? 'Buck' : 'Doe'}</td>
                        <td>
                          <span
                            role='button'
                            onClick={() => {
                              setShowModal(true)
                              setAnimalForm(ancestral)
                              setDisabled(true)
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/img/icons/view.png`}
                              alt='View'
                              className={`mb-2 p-r-3 `}
                              title='View'
                            />
                          </span>

                          <span
                            role='button'
                            onClick={() => {
                              setShowModal(true)
                              setAnimalForm(ancestral)
                              setDisabled(false)
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/img/icons/update.png`}
                              alt='update'
                              className={`mb-2 p-r-3 `}
                              title='Update'
                            />
                          </span>
                          <span
                            role='button'
                            onClick={() => deleteAncestral(ancestral.rabbit_id)}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/img/icons/delete.png`}
                              alt='Delete'
                              className={`mb-2 p-r-3 `}
                              title='Delete'
                            />
                          </span>
                        </td>
                      </tr>
                    )
                  })
                ) : AncestralArchiveLoading ? (
                  <tr>
                    <td colSpan={7} className='text-center'>
                      <Spinner size='sm' />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan={7} className='text-center'>
                      No Ancestral archive found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Pagination paginationClick={paginationClick} />
            <Modal isOpen={showModal} size='lg'>
              <ModalHeader toggle={onCloseModal}>Rabbit</ModalHeader>
              <ModalBody>
                {showModal ? (
                  <RabbitForm
                    form={animalForm}
                    onCloseModal={onCloseModal}
                    disabled={disabled}
                    setReload={setReload}
                    reload={reload}
                    setIsReloadComponentCall={setIsReloadComponentCall}
                  />
                ) : null}
              </ModalBody>
            </Modal>
          </Col>
        </CardBody>
      </Row>
    </Card>
  )
}

export default AncestralArchive

export const SearchComponent = () => {
  const dispatch = useDispatch()

  const { values } = useFormikContext()

  useEffect(() => {
    const data = {
      params: {
        _page: 1,
        _limit: LIMIT,
        _sort: 'breed_name',
        _order: 'ASC',
        search: values?.search || '',
      },
    }

    dispatch(ancestorList(data))
  }, [values?.search || ''])
  return null
}

export const ReloadComponent = ({ reload, isDelete }) => {
  const dispatch = useDispatch()

  const { values } = useFormikContext()

  const { AncestralArchive, page } = useSelector((state) => ({
    AncestralArchive:
      state.herdManagement.AncestralArchive.AncestralArchive.list,

    page: state?.herdManagement?.pagination?.page || 1,
  }))

  useEffect(() => {
    let finalPage = page

    if (
      Array.isArray(AncestralArchive) &&
      AncestralArchive.length === 1 &&
      isDelete
    ) {
      finalPage -= 1
    }
    const data = {
      params: {
        _page: finalPage,
        _limit: LIMIT,
        _sort: 'breed_name',
        _order: 'ASC',
        search: values?.search || '',
      },
    }
    dispatch(ancestorList(data))
  }, [reload])
  return null
}
