import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { get, post } from '../../../../helpers/api_helper'
import CustomizedDialogs from './popup'
import * as url from '../../../../helpers/url_helper'
import { config } from '../../../../store/shoppingCart/saga'
import { useDispatch } from 'react-redux'
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap'
import {
  loadUser,
  premierUsers,
  setPremiumModal,
} from '../../../../store/actions'
import _ from 'lodash'
import Banner from './Banner'
import classNames from 'classnames'

export const Loginform = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [inputs, setInputs] = useState({
    role: 'user',
    termsAndConditions: false,
  })

  const [list, setList] = useState([])
  const [showBanner, setShowBanner] = useState(false)
  const [bannerLoading, setBannerLoading] = useState(true)

  const [alreadyAcceptTermAndCondition, setAlreadyAcceptTermAndCondition] =
    useState()
  const [showPassword, setShowPassword] = useState(false)
  let showPwd = () => {
    setShowPassword(true)
  }
  let hidePwd = () => {
    setShowPassword(false)
  }
  const [errors, setErrors] = useState({
    email: null,
    password: null,
  })
  const [isNotValid, setIsNotValid] = useState(false)
  let [showBtn, setShowBtn] = useState(false)

  const [valiadtionMsg, setValiadtionMsg] = useState('')
  const [showModal, setShowModal] = useState(false)

  const [showAlert, setShowAlert] = useState('')
  const [msg, setMsg] = useState('')
  const [activeTab, setActiveTab] = useState('user')

  const [uniqueString, setUniqueString] = useState(null)

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

  const handleChange = (event) => {
    let name = event.target.name
    let value = event.target.value

    // setInputs((values) => ({ ...values, [name]: value }))

    let err = {}
    if (name === 'email') {
      // err.email = !value || !emailRegex.test(value) ? true : null

      if (value.trim()) {
        get(
          `/api/v1/register/is_email_exist?email=${value.trim()}&user_role=${
            inputs.role
          }`
        )
          .then((data) => {
            setAlreadyAcceptTermAndCondition(
              Boolean(data?.terms_and_condition || false)
            )

            if (
              data.is_exist === true &&
              data.account_status === 'UNVERIFIED'
            ) {
              // document.getElementById('smg-btn').disabled = true
              setShowAlert(true)
              setShowBtn(true)
              setMsg(
                'Your account is not verified yet. Please click on resend verification link on website. Verify  your account to login.'
              )
            }
            if (data.is_exist === true && data.account_status === null) {
              // document.getElementById('smg-btn').disabled = true
              setUniqueString(data.secretary_verification_code)
              setShowAlert(true)
              setShowBtn(true)
              setMsg(
                'Your account is not verified yet. Please click on resend verification link on website. Verify  your account to login.'
              )
            }
          })
          .catch((error) => {})
      } else {
        setAlreadyAcceptTermAndCondition(Boolean(false))
      }
    }
    if (name === 'email') {
      setInputs({ ...inputs, [name]: value.replace(/\s/g, '') })

      //  if(event.)
    } else {
      setInputs({ ...inputs, [name]: value })
    }

    // setInputs((values) => ({ ...values, [name]: value }))
  }
  const handleTabChange = (val) => {
    setInputs({})
    // inputElement.current.focus()
    if (val === 'tab1') {
      setActiveTab('user')
      setAlreadyAcceptTermAndCondition(false)
      setInputs((values) => ({
        ...values,
        role: 'user',
        email: '',
        password: '',
        termsAndConditions: false,
      }))
      let tab1 = document.getElementById('tab1')
      let tab2 = document.getElementById('tab2')
      document.getElementById('email').focus()
      tab1.classList.add('active')
      tab2.classList.remove('active')
      setErrors({
        email: null,
        password: null,
      })
    } else {
      setActiveTab('secretary')
      setAlreadyAcceptTermAndCondition(false)
      setInputs((values) => ({
        ...values,
        role: 'secretary',
        email: '',
        password: '',
        termsAndConditions: false,
      }))
      let tab1 = document.getElementById('tab1')
      let tab2 = document.getElementById('tab2')
      tab1.classList.remove('active')
      document.getElementById('email').focus()
      tab2.classList.add('active')
      setErrors({
        email: null,
        password: null,
      })
    }
  }

  let handleClicked = () => {
    document.getElementById('smg-btn').disabled = true
    if (activeTab === 'user') {
      post(`/api/v1/user_activation/resend_verify_link`, {
        email: inputs.email,
      })
        .then((response) => {
          setShowBtn(false)

          setMsg(
            'Email verify link has been successfully sent to your registered Email'
          )
          setInputs({})
          document.getElementById('smg-btn').disabled = false
        })
        .catch((error) => {
          setShowBtn(false)

          console.log(error)
          document.getElementById('smg-btn').disabled = false
        })
    }

    if (activeTab === 'secretary') {
      const body = JSON.stringify({ unique_string: uniqueString })
      post(url.RESEND_VERIFICATION_EMAIL, body, config)
        .then((res) => {
          if (res) {
            setShowAlert(true)
            setShowBtn(false)
            setMsg(
              'Email verify link has been successfully sent to your registered Email'
            )
            // setInputs({});
            document.getElementById('smg-btn').disabled = false
            // }
          }
        })
        .catch((err) => {
          setShowBtn(false)
          document.getElementById('smg-btn').disabled = false
        })
    }
  }
  const fetchPremierUser = (body) => {
    return new Promise((resolve, reject) => {
      dispatch(
        premierUsers(body, (err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      )
    })
  }
  const handleLogin = async (e) => {
    e.preventDefault()
    let validationFlag = false
    let err = {}
    if (!inputs.email) {
      validationFlag = true
      err.email = 'Email field is required'
    } else if (inputs.email && !emailRegex.test(inputs.email)) {
      validationFlag = true
      err.email = 'Invalid email address.'
    }
    if (!inputs?.termsAndConditions && !alreadyAcceptTermAndCondition) {
      validationFlag = true
      err.termsAndConditions = 'Please accept the terms and conditions'
    }
    if (!inputs.password) {
      validationFlag = true
      err.password = true
    }
    setErrors(err)
    if (!validationFlag) {
      document.getElementById('submit').setAttribute('disabled', true)
      let accessToken = localStorage.getItem('accessToken')
      if (!accessToken) {
        let body = {
          role: inputs.role,
          email: inputs.email,
          password: inputs.password,
        }
        if (inputs?.termsAndConditions) {
          body.terms_and_condition = inputs.termsAndConditions ? 1 : 0
        }
        post(`/api/v1/login`, body)
          .then(async (response) => {
            if (response?.data.role === 'secretary') {
              toast.success('Login Success !')
              localStorage.setItem('secretaryToken', response.accessToken)
              localStorage.setItem(
                'secretaryRefreshToken',
                response.refreshToken
              )

              localStorage.setItem('secretary', JSON.stringify(response.data))
              setTimeout(() => {
                window.open(
                  `${process.env.REACT_APP_SECRETARY_URL}/token?token=${response.accessToken}&refresh=${response.refreshToken}`,
                  '_self'
                )
                // window.location.href =
              }, 1500)
            }

            if (response?.data.role === 'user') {
              toast.success('Login Success !')
              localStorage.setItem('accessToken', response.accessToken)
              localStorage.setItem('refreshToken', response.refreshToken)
              localStorage.setItem('authUser', JSON.stringify(response.data))
              dispatch({
                type: 'LOGIN_SUCCESS',
                payload: {
                  token: response.accessToken,
                  user: response.data,
                },
              })
              dispatch(loadUser())
              // let event = localStorage.getItem('events')
              // event = event ? JSON.parse(event) : null

              if (response?.data?.id) {
                const body = {
                  user_id: response?.data?.id,
                }
                const resPremierUser = await fetchPremierUser(body)
                const premierUser = _.first(resPremierUser)
                if (!premierUser || premierUser?.plan_expired === 1) {
                  const modalData = {
                    showModal: true,
                  }
                  dispatch(setPremiumModal(modalData))
                }
              }

              // if (event) {
              //   history.push({ pathname: '/additional_products' })
              // } else {
              history.push({ pathname: '/auth-events' })
              // }
            }
          })
          .catch((error) => {
            document.getElementById('submit').removeAttribute('disabled')
            if (error.response?.status === 401) {
              if (error.response?.data === 'Invalid credentials.') {
                toast.error(error.response.data, { toastId: 1 })
              } else {
                setShowAlert(true)
                if (
                  error.response?.data ===
                  'Please contact Admin you are currently blocked.'
                ) {
                  setShowBtn(false)
                } else {
                  setShowBtn(true)
                }
                setMsg(error.response.data)
                //toast.error(error.response.data);
              }
            } else if (error.response?.status === 422) {
              toast.error(error.response.data[0].msg)
            } else if (error.response?.status === 500) {
              toast.error(error.response.data)
            } else {
            }
          })
      } else {
        document.getElementById('submit').removeAttribute('disabled')
        toast.error(
          'You already have an active login session in this browser !',
          { toastId: 1 }
        )
      }
    }
  }

  useEffect(() => {
    const fun = async () => {
      setBannerLoading(true)
      try {
        const res = await get(
          `/api/v1/easyfied/equipment-product-doc/front-listing-all`
        )

        setList(
          _.map(
            _.filter(res?.data, (f) => f.doc_type === 'img'),
            (d) => {
              return d
            }
          ) || []
        )
        if (_.size(_.filter(res?.data, (f) => f.doc_type === 'img'))) {
          setShowBanner(true)
        }
        setBannerLoading(false)
      } catch (err) {
        setList([])
        setShowBanner(false)
        setBannerLoading(false)
        if (err) {
          if (Array.isArray(err.response.data)) {
            toast.error(
              _.join(
                _.map(err.response.data, (_e) => _e.msg),
                ','
              )
            )
          }
        } else if (err.response.data) {
          toast.error(err.response.data)
        }
      }
    }
    fun()
  }, [])

  return (
    <React.Fragment>
      <div className='view-main mt-10 res-mt-view'>
        <section className='bg-cover bg-no-repeat loginform login-banner'>
          <div className='container-fuild'>
            <Col md={12} style={{ marginTop: '120px' }}>
              <p className='text-center gift-card  fw-bolder text-dark pre-exh'>
                Premier Exhibitor available for purchase,{'  '}
                <Link
                  to='/premier-membership'
                  className='text-decoration-underline'
                >
                  click here
                </Link>
                {'  '}to purchase your premier exhibitor. If you don't have a
                basic account{' '}
                <Link to='/register' className='text-decoration-underline'>
                  click here
                </Link>{' '}
                to register{' '}
              </p>
            </Col>
            <div
              className={classNames('row', {
                'align-items-center justify-content-center min-vh-100':
                  !showBanner && !_.size(list) && !bannerLoading,
              })}
            >
              <div className='col-md-12 col-xl-7 text-center'>
                {!bannerLoading && showBanner && _.size(list) ? (
                  <Banner list={list} />
                ) : !bannerLoading && !showBanner && !_.size(list) ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/img/user-login.png`}
                    alt='banner-logo'
                    className='logo-max-view'
                  />
                ) : null}
              </div>

              {/* <div className='col-md-12 col-xl-2'></div> */}
              <div className='col-md-12 col-xl-5'>
                <div className='card'>
                  <div className='card-body user-tab'>
                    <div className='card'>
                      <div className='bg-blue card-body'>
                        <ul className='nav nav-tabs'>
                          <li
                            className='nav-item nav-res1'
                            onClick={() => {
                              handleTabChange('tab1')
                            }}
                          >
                            <a className={'active nav-link'} id='tab1'>
                              <span className='d-none1 d-sm-block1'>
                                USER LOGIN
                              </span>
                            </a>
                          </li>
                          <li
                            className='nav-item nav-res2'
                            onClick={() => {
                              handleTabChange('tab2')
                            }}
                          >
                            <a className='nav-link' id='tab2'>
                              <span className='d-none d-sm-none1'>
                                <i className='far fa-user'></i>
                              </span>
                              <span className='d-none1 d-sm-block1'>
                                SECRETARY LOGIN
                              </span>
                            </a>
                          </li>
                        </ul>
                        <div className='tab-content p-3 bg-white pt-4'>
                          <div className='tab-pane active'>
                            <form>
                              <div className='form-group mb-4'>
                                <label className='form-label'>Email Id *</label>
                                <input
                                  type='text'
                                  name='email'
                                  placeholder='Email Id'
                                  className='form-control'
                                  id='email'
                                  value={inputs.email}
                                  onChange={(e) => handleChange(e)}
                                  // onKeyDown={handleChange}
                                  style={{ borderColor: 'black' }}
                                  autoFocus
                                />
                                {errors.email && (
                                  <p style={{ color: 'red' }}>
                                    {errors?.email}
                                  </p>
                                )}
                              </div>

                              <div className='form-group mb-4'>
                                <label className='form-label'>Password *</label>
                                <input
                                  name='password'
                                  placeholder='Password'
                                  className='form-control'
                                  value={inputs.password || ''}
                                  onChange={handleChange}
                                  style={{ borderColor: 'black' }}
                                  type={showPassword ? 'text' : 'password'}
                                />
                                {showPassword && (
                                  <RemoveRedEyeIcon
                                    className='pwd-icon'
                                    onClick={hidePwd}
                                  />
                                )}
                                {!showPassword && (
                                  <VisibilityOffIcon
                                    className='pwd-icon'
                                    onClick={showPwd}
                                  />
                                )}
                                {errors.password && (
                                  <p style={{ color: 'red' }}>
                                    Password field is required
                                  </p>
                                )}
                              </div>
                              {/* {!alreadyAcceptTermAndCondition ? ( */}
                              <div
                                style={{
                                  visibility: alreadyAcceptTermAndCondition
                                    ? 'hidden'
                                    : 'visible',
                                }}
                              >
                                <FormGroup check className='mb-2'>
                                  <Input
                                    type='checkbox'
                                    className='form-check-input-v2'
                                    onClick={(e) => {
                                      setInputs((prev) => ({
                                        ...prev,
                                        termsAndConditions:
                                          !inputs.termsAndConditions,
                                      }))
                                    }}
                                    checked={inputs.termsAndConditions}
                                    id='term-and-condition'
                                  />
                                  <Label check htmlFor='term-and-condition'>
                                    I have read and agree to the{' '}
                                    <span
                                      className='text-primary fw-bold text-decoration-underline'
                                      role='button'
                                      onClick={() => setShowModal(!showModal)}
                                    >
                                      {' '}
                                      Website Terms Conditions & Privacy Policy
                                    </span>{' '}
                                    *
                                  </Label>{' '}
                                </FormGroup>
                                {errors.termsAndConditions ? (
                                  <p style={{ color: 'red' }}>
                                    {errors.termsAndConditions}
                                  </p>
                                ) : null}
                              </div>
                              {/* ) : null} */}

                              <div className='col-md-12 col-xl-12 pt-2 text-center'>
                                <button
                                  className='col-xl-8 btn btn-primary w-1001'
                                  onClick={handleLogin}
                                  id='submit'
                                >
                                  SUBMIT
                                </button>
                              </div>
                              <div className='mt-4 text-center text-font'>
                                Don’t have an account?{' '}
                                <Link
                                  to='/register'
                                  className='font-weight-bold link'
                                >
                                  SIGN UP
                                </Link>
                              </div>
                              <div className='mt-2 mb-2 text-center text-font'>
                                {' '}
                                <Link
                                  to={`/forgot-password?user=${inputs.role}`}
                                  className='font-weight-bold'
                                >
                                  Forgot Password?
                                </Link>
                              </div>
                              {activeTab === 'user' ? (
                                <div className='mt-2 mb-2 text-center text-font'>
                                  <a
                                    href='https://www.easy2show.com/assets/pdf/E2S_How_2_Revised_New_Users_Registrations_Premier_Exhibitor.pdf'
                                    target='_blank'
                                    className='font-weight-bold hw-color fs-6'
                                    rel='noreferrer'
                                  >
                                    How 2 User Registration, Login and Premier
                                    Exhibitor
                                  </a>
                                </div>
                              ) : (
                                <div className='mt-2 mb-2 text-center text-font'>
                                  <a
                                    href='https://www.easy2show.com/assets/pdf/How2-Secretary-Registration.pdf'
                                    target='_blank'
                                    className='font-weight-bold hw-color'
                                    rel='noreferrer'
                                  >
                                    How 2 Secretary Registration and Login
                                  </a>
                                </div>
                              )}
                            </form>
                          </div>
                          <div className='tab-pane'>
                            <form>
                              <div className='form-group mb-4'>
                                <label className='form-label'>Email Id *</label>
                                <input
                                  type='email'
                                  name='email'
                                  placeholder='Email Id'
                                  value={inputs.email}
                                  className='form-control'
                                  style={{ borderColor: 'black' }}
                                />
                              </div>
                              <div className='form-group mb-4'>
                                <label className='form-label'>Password *</label>
                                <input
                                  type='password'
                                  name='password'
                                  placeholder='Password'
                                  className='form-control'
                                  value=''
                                  style={{ borderColor: 'black' }}
                                />
                              </div>
                              <div className='col-md-12 col-xl-12 pt-2 text-center'>
                                <button
                                  className='col-xl-8 btn btn-primary w-1001'
                                  onClick={handleLogin}
                                >
                                  SUBMIT
                                </button>
                              </div>
                              <div className='mt-4 text-center text-font'>
                                Don’t have an account?{' '}
                                <a
                                  className='font-weight-bold'
                                  href='register.html'
                                >
                                  SIGN UP
                                </a>
                              </div>
                              <div className='mt-2 mb-2 text-center text-font'>
                                {' '}
                                <a
                                  className='font-weight-bold'
                                  href='forget_password.html'
                                >
                                  Forgot Password?
                                </a>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <CustomizedDialogs
          setShowAlert={setShowAlert}
          msg={msg}
          showAlert={showAlert}
          setMsg={setMsg}
          setShowBtn={setShowBtn}
          showBtn={showBtn}
          handleClicked={handleClicked}
        />
        <Modal isOpen={showModal} centered={true} className='term-modal'>
          <div className='modal-header' style={{ background: '#fff' }}>
            <h4 className='modal-title mt-0'>
              Website Terms and Conditions and Privacy Policy
            </h4>
            <button
              type='button'
              onClick={() => {
                setShowModal(!showModal)
              }}
              className='close close-btn'
              data-dismiss='modal'
              aria-label='Close'
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <ModalBody className='bg-white'>
            <Row>
              {/* <div className="col-md-12 col-lg-12 col-xs-12 text-center mt-3">
                <h2 className="top-title">
                  <span> Website Terms and Conditions</span>
                </h2>
              </div> */}

              <Col lg={12}>
                <div className='col-md-12 col-lg-12 col-xs-12 terms'>
                  <p className='term-date'>
                    <i>Last updated: August 26, 2024 </i>
                  </p>
                  <p className='mt-4'>
                    <strong>
                      IMPORTANT: The headings and summaries provided herein are
                      for convenience only, and do not in any way detract or add
                      to the meaning or intent of the Terms. They are not a
                      substitute for careful consideration of these Terms.
                      Please read these Terms carefully.
                    </strong>
                  </p>
                  <p>
                    <strong>
                      <u>Acceptance of the Terms of Use</u>
                    </strong>
                  </p>

                  <p>
                    These terms and conditions are entered into by and between
                    You and{' '}
                    <strong>
                      {' '}
                      Advanced Rabbit Solutions, LLC, DBA Easy2Show.
                    </strong>{' '}
                    (the “<strong>Company</strong>”). The following terms and
                    conditions, together with any documents they expressly
                    incorporate by reference (collectively, the “
                    <strong>Terms</strong>”), govern your access to and use of{' '}
                    <strong>Easy2Show</strong> including any content,
                    functionality, and services offered on or through (the “
                    <strong>Website</strong>”), whether as a guest or a
                    registered user.
                  </p>

                  <p>
                    Please read the Terms carefully before you start to use the
                    Website.{' '}
                    <strong>
                      By using the Website or by clicking to accept or agree to
                      the Terms when this option is made available to you, you
                      accept and agree to be bound and abide by these Terms and
                      comply with all applicable laws and regulations.
                    </strong>
                    If you do not want to agree to these Terms, you must not
                    access or use the Website.
                  </p>

                  <p>
                    This Website is offered and available to users who are
                    thirteen (13) years of age or older and reside in the United
                    States or any of its territories or possessions. By using
                    this Website, you represent and warrant that you are of
                    legal age to form a binding contract with the Company and
                    meet all of the foregoing eligibility requirements. If you
                    do not meet all of these requirements, you must not access
                    or use the Website.
                  </p>

                  <p>
                    By agreeing to the these terms and conditions, you authorize
                    us to put you onto our mailing list for future
                    correspondence including but not limited to; updates,
                    specials, promotions, etc.
                  </p>

                  <p>
                    <strong>
                      <u>Changes to the Terms</u>
                    </strong>
                  </p>
                  <p>
                    The Company may revise and update these Terms from time to
                    time in our sole discretion. All changes and modifications
                    shall immediately become effective upon posting and apply to
                    all access to and use of the Website thereafter. Your
                    continued use of the Website following the posting of
                    revised Terms means that you accept and agree to the
                    changes. You are expected to check this page from time to
                    time so you are aware of any changes, as they are binding on
                    you.
                  </p>

                  <p>
                    <strong>
                      <u>Accessing the Website and User Account Security</u>
                    </strong>
                  </p>
                  <p>
                    We reserve the right to withdraw or amend this Website, and
                    any service or material we provide on the Website, in our
                    sole discretion without notice. We will not be liable if for
                    any reason all or any part of the Website is unavailable at
                    any time or for any period. From time to time, we may
                    restrict access to some parts of the Website, or the entire
                    Website, to users, including registered users.
                  </p>
                  <p>You are responsible for both:</p>
                  <ul>
                    <li>
                      Making all arrangements necessary for you to have access
                      to the Website.
                    </li>
                    <li>
                      Ensuring that all persons who access the Website through
                      your internet connection are aware of these Terms and
                      comply with them.
                    </li>
                  </ul>
                  <p>
                    To access the Website or some of the resources it offers,
                    you may be asked to provide certain registration details or
                    other information. It is a condition of your use of the
                    Website that all the information you provide on the Website
                    is correct, current, and complete. If you choose, or are
                    provided with, a user name, password, or any other piece of
                    information as part of our security procedures, you must
                    treat such information as confidential, and you must not
                    disclose it to any other person or entity. You also
                    acknowledge that your account is personal to you and agree
                    not to provide any other person with access to this Website
                    or portions of it using your user name, password, or other
                    security information. You agree to notify us immediately of
                    any unauthorized access to or use of your user name or
                    password or any other breach of security. You also agree to
                    ensure that you exit from your account at the end of each
                    session. You should use particular caution when accessing
                    your account from a public or shared computer so that others
                    are not able to view or record your password or other
                    personal information.
                  </p>
                  <p>
                    We have the right to disable any user name, password, or
                    other identifier, whether chosen by you or provided by us,
                    at any time in our sole discretion for any or no reason,
                    including if, in our opinion, you have violated any
                    provision of these Terms.
                  </p>
                  <p>
                    <strong>
                      <u>Intellectual Property Rights</u>
                    </strong>
                  </p>
                  <p>
                    The Website and its entire contents, features, and
                    functionality (including but not limited to all information,
                    software, text, displays, images, video, and audio, and the
                    design, selection, and arrangement thereof) are owned by the
                    Company, its licensors, or other providers of such material
                    and are protected by United States and international
                    copyright, trademark, patent, trade secret, and other
                    intellectual property or proprietary rights laws.
                  </p>
                  <p>
                    You must not reproduce, distribute, modify, create
                    derivative works of, publicly display, publicly perform,
                    republish, download, store, or transmit any of the material
                    on our Website, except as follows:
                  </p>
                  <ul>
                    <li>
                      Your computer may temporarily store copies of such
                      materials in RAM incidental to your accessing and viewing
                      those materials.
                    </li>
                    <li>
                      You may store files that are automatically cached by your
                      Web browser for display enhancement purposes.
                    </li>
                    <li>
                      You may print or download one copy of a reasonable number
                      of pages of the Website for your own personal use and not
                      for further reproduction, publication, or distribution.
                    </li>
                    <li>
                      If we provide social media features with certain content,
                      you may take such actions as are enabled by such features.
                    </li>
                  </ul>
                  <p>You must not:</p>
                  <ul>
                    <li>Modify copies of any materials from this site.</li>
                    <li>
                      Use any illustrations, photographs, video or audio
                      sequences, or any graphics separately from the
                      accompanying text.
                    </li>
                    <li>
                      Delete or alter any copyright, trademark, or other
                      proprietary rights notices from copies of materials from
                      this Website.
                    </li>
                  </ul>
                  <p>
                    If you print, copy, modify, download, or otherwise use or
                    provide any other person with access to any part of the
                    Website in breach of these Terms, your right to use the
                    Website will stop immediately and you must, at our option,
                    return or destroy any copies of the materials you have made.
                    No right, title, or interest in or to the Website or any
                    content on the Website is transferred to you, and all rights
                    not expressly granted are reserved by the Company.
                    Any use of the Website not expressly permitted by
                    these Terms is a breach of the same and may violate
                    copyright, trademark, and other laws.
                  </p>

                  <p>
                    <strong>
                      <u>Trademarks</u>
                    </strong>
                  </p>

                  <p>
                    The Company name, the Company logo, and all related names,
                    logos, product and service names, designs, and slogans are
                    trademarks of the Company or its affiliates or licensors.
                    You must not use such marks without the prior written
                    permission of the Company. All other names, logos, product
                    and service names, designs, and slogans on this Website are
                    the trademarks of their respective owners.
                  </p>

                  <p>
                    <strong>
                      <u>Prohibited Uses</u>
                    </strong>
                  </p>

                  <p>
                    You may use the Website for lawful purposes and in
                    accordance with these Terms. You agree not to use the
                    Website:
                  </p>
                  <ul>
                    <li>
                      In any way that violates any applicable federal, state,
                      local, or international law or regulation (including,
                      without limitation, any laws regarding the export of data
                      or software to and from the US or other countries).
                    </li>
                    <li>
                      To transmit, or procure the sending of, any advertising or
                      promotional material without our prior written consent,
                      including any “junk mail,” “chain letter,” “spam,” or any
                      other similar solicitation.
                    </li>
                    <li>
                      To engage in any other conduct that restricts or inhibits
                      anyone's use or enjoyment of the Website, or which, as
                      determined by us, may harm the Company or users of the
                      Website, or expose them to liability.
                    </li>
                  </ul>

                  <p>Additionally, you agree not to:</p>

                  <ul>
                    <li>
                      Use the Website in any manner that could disable,
                      overburden, damage, or impair the site or interfere with
                      any other party’s use of the Website, including their
                      ability to engage in real time activities through the
                      Website.
                    </li>
                    <li>
                      Use any robot, spider, or other automatic device, process,
                      or means to access the Website for any purpose, including
                      monitoring or copying any of the material on the Website.
                    </li>
                    <li>
                      Use any manual process to monitor or copy any of the
                      material on the Website, or for any other purpose not
                      expressly authorized in these Terms, without our prior
                      written consent.
                    </li>
                    <li>
                      Use any device, software, or routine that interferes with
                      the proper working order of the Website.
                    </li>
                    <li>
                      Introduce any viruses, Trojan horses, worms, logic bombs,
                      or other material that is malicious or technologically
                      harmful.
                    </li>
                    <li>
                      Attempt to gain unauthorized access to, interfere with,
                      damage, or disrupt any parts of the Website, the server on
                      which the Website is stored, or any server, computer, or
                      database connected to the Website.
                    </li>
                    <li>
                      Attack the Website via a denial-of-service attack or a
                      distributed denial-of-service attack.
                    </li>
                    <li>
                      Otherwise attempt to interfere with the proper working
                      order of the Website.
                    </li>
                  </ul>

                  <p>
                    <strong>
                      <u>Reliance on Information Posted</u>
                    </strong>
                  </p>
                  <p>
                    The information presented on or through the Website is made
                    available solely for general information purposes. We do not
                    warrant the accuracy, completeness, or usefulness of this
                    information. Any reliance you place on such information is
                    strictly at your own risk. We disclaim all liability and
                    responsibility arising from any reliance placed on such
                    materials by you or any other visitor to the Website, or by
                    anyone who may be informed of any of its contents.
                  </p>

                  <p>
                    This Website may include content provided by third parties,
                    including materials provided by other users, bloggers, and
                    third-party licensors, syndicators, aggregators, and/or
                    reporting services. All statements and/or opinions expressed
                    in these materials, and all articles and responses to
                    questions and other content, other than the content provided
                    by the Company, are solely the opinions and the
                    responsibility of the person or entity providing those
                    materials. These materials do not necessarily reflect the
                    opinion of the Company. We are not responsible, or liable to
                    you or any third party, for the content or accuracy of any
                    materials provided by any third parties.
                  </p>

                  <p>
                    <strong>
                      <u>Changes to the Website</u>
                    </strong>
                  </p>
                  <p>
                    We may update the content on this Website from time to time,
                    but its content is not necessarily complete or up-to-date.
                    Any of the material on the Website may be out of date at any
                    given time, and we are under no obligation to update such
                    material.
                  </p>

                  <p>
                    <strong>
                      <u>Information and You and Your Visits to the Website</u>
                    </strong>
                  </p>
                  <p>
                    All information we collect on this Website is subject to our
                    Privacy Policy. By using the Website, you consent to all
                    actions taken by us with respect to your information in
                    compliance with the Privacy Policy.
                  </p>

                  <p>
                    <strong>
                      <u>Online Purchases and Other Terms and Conditions</u>
                    </strong>
                  </p>
                  <p>
                    All purchases through the Website or other transactions for
                    the sale of goods formed through the Website, or resulting
                    from visits made by you, are governed by the Terms. When
                    completing such purchases, these payments are final and
                    non-refundable, unless otherwise determined by the Company.
                    The Company, in its sole discretion, may offer credit or
                    refunds on a case-by-case basis including, by way of
                    example, in the event of an error with your order or in the
                    amounts you were charged.
                  </p>

                  <p>
                    The Company will charge, and you authorize the Company to
                    charge, the payment method you specify at the time of
                    purchase. If you pay any amounts with a credit card, the
                    Company may seek pre- authorization of your credit card
                    account prior to your purchase to verify that the credit
                    card is valid and has credit available for your intended
                    purchase.
                  </p>

                  <p>
                    The Company reserves the right to establish, remove, and/or
                    revise prices, fees, and/or surcharges for any or all goods
                    obtained through the use of the Website at any time. The
                    Company may also, in its sole discretion, make promotional
                    offers with different features and different rates to any or
                    all of our customers. Unless made to you, these promotional
                    offers will have no bearing on your obligation to pay the
                    amounts charged.
                  </p>

                  <p>
                    <strong>
                      <u>Linking to Website and Social Media Features</u>
                    </strong>
                  </p>
                  <p>
                    You may link to our homepage, provided you do so in a way
                    that is fair and legal and does not damage our reputation or
                    take advantage of it, but you must not establish a link in
                    such a way as to suggest any form of association, approval,
                    or endorsement on our part without our express, written
                    consent.
                  </p>
                  <p>
                    This Website may provide certain social media features that
                    enable you to:
                  </p>
                  <ul>
                    <li>
                      Link from your own or certain third-party websites to
                      certain content on this Website.
                    </li>
                    <li>
                      Send emails or other communications with certain content,
                      or links to certain content, on this Website.
                    </li>
                    <li>
                      Cause limited portions of content on this Website to be
                      displayed or appear to be displayed on your own or certain
                      third-party websites.
                    </li>
                  </ul>
                  <p>
                    You may use these features solely as they are provided by
                    the Company, solely with respect to the content they are
                    displayed with, and otherwise in accordance with any
                    additional terms and conditions we provide with respect to
                    such features. Subject to the foregoing, you must not:
                  </p>

                  <ul>
                    <li>
                      Establish a link from any website that is not owned by
                      you.
                    </li>
                    <li>
                      Cause the Website or portions of it to be displayed on, or
                      appear to be displayed by, any other site, for example,
                      framing, deep linking, or in-line linking.
                    </li>
                    <li>
                      Link to any part of the Website other than the homepage.
                    </li>
                    <li>
                      Otherwise take any action with respect to the materials on
                      this Website that is inconsistent with any other provision
                      of these Terms.
                    </li>
                  </ul>

                  <p>
                    You agree to cooperate with us in causing any unauthorized
                    framing or linking immediately to stop. We reserve the right
                    to withdraw linking permission without notice.
                  </p>

                  <p>
                    We may disable all or any social media features and any
                    links at any time without notice in our sole discretion.
                  </p>

                  <p>
                    <strong>
                      <u>Third-Party Links from Website</u>
                    </strong>
                  </p>
                  <p>
                    If the Website contains links to other sites and resources
                    provided by third parties, these links are provided for your
                    convenience only. This includes links contained in
                    advertisements, including banner advertisements and
                    sponsored links. We have no control over the contents of
                    those sites or resources, and accept no responsibility for
                    them or for any loss or damage that may arise from your use
                    of them. If you decide to access any of the third-party
                    websites linked to this Website, you do so entirely at your
                    own risk and subject to the terms and conditions of use for
                    such websites.
                  </p>

                  <p>
                    <strong>
                      <u>Geographic Restrictions</u>
                    </strong>
                  </p>
                  <p>
                    The owner of the Website is based in the State of Iowa in
                    the United States. We provide this Website for use only by
                    persons located in the United States. We make no claims that
                    the Website or any of its content is accessible or
                    appropriate outside of the United States. Access to the
                    Website may not be legal by certain persons or in certain
                    countries. If you access the Website from outside the United
                    States, you do so on your own initiative and are responsible
                    for compliance with local laws.
                  </p>

                  <p>
                    <strong>
                      <u>Disclaimer of Warranties</u>
                    </strong>
                  </p>

                  <p>
                    You understand that we cannot and do not guarantee or
                    warrant that files available for downloading from the
                    internet or the Website will be free of viruses or other
                    destructive code. You are responsible for implementing
                    sufficient procedures and checkpoints to satisfy your
                    particular requirements for anti- virus protection and
                    accuracy of data input and output, and for maintaining a
                    means external to our site for any reconstruction of any
                    lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE
                    CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES,
                    OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT
                    YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER
                    PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY
                    GOODS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF
                    ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
                  </p>
                  <p>
                    YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY GOODS OBTAINED
                    THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS
                    CONTENT, AND ANY GOODS OBTAINED THROUGH THE WEBSITE ARE
                    PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY
                    WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER
                    THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES
                    ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
                    COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
                    AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING,
                    NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY
                    REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY
                    GOODS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE,
                    RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE
                    CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT
                    AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS,
                    OR THAT THE WEBSITE OR ANY GOODS OBTAINED THROUGH THE
                    WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
                  </p>

                  <p>
                    <strong>
                      <u>
                        THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND,
                        WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE,
                        INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
                        MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
                        PARTICULAR PURPOSE.
                      </u>
                    </strong>
                  </p>
                  <p>
                    THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE
                    EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                  </p>

                  <p>
                    <strong>
                      <u>Limitation on Liability</u>
                    </strong>
                  </p>

                  <p>
                    IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR
                    LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS,
                    OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY
                    LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE,
                    OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT,
                    ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING
                    ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR
                    PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL
                    INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF
                    REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED
                    SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND
                    WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF
                    CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
                  </p>

                  <p>
                    THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE
                    EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                  </p>

                  <p>
                    <strong>
                      <u>Indemnification</u>
                    </strong>
                  </p>
                  <p>
                    You agree to defend, indemnify, and hold harmless the
                    Company, its affiliates, licensors, and service providers,
                    and its and their respective officers, directors, employees,
                    contractors, agents, licensors, suppliers, successors, and
                    assigns from and against any claims, liabilities, damages,
                    judgments, awards, losses, costs, expenses, or fees
                    (including reasonable attorneys’ fees) arising out of or
                    relating to your violation of these Terms or your use of the
                    Website, including, but not limited to, any use of the
                    Website's content, services, and products other than as
                    expressly authorized in these Terms or your use of any
                    information obtained from the Website.
                  </p>

                  <p>
                    <strong>
                      <u>Governing Law</u>
                    </strong>
                  </p>
                  <p>
                    All matters relating to the Website and these Terms, and any
                    dispute or claim arising therefrom or related thereto (in
                    each case, including non-contractual disputes or claims),
                    shall be governed by and construed in accordance with the
                    internal laws of the State of Wisconsin without giving
                    effect to any choice or conflict of law provision or rule
                    (whether of the State of Wisconsin or any other
                    jurisdiction).
                  </p>
                  <p>
                    <strong>
                      <u>Arbitration</u>
                    </strong>
                  </p>
                  <p>
                    <strong>
                      <u>
                        BY AGREEING TO THESE TERMS, YOU AGREE THAT YOU ARE
                        REQUIRED TO RESOLVE ANY CLAIM THAT YOU MAY HAVE AGAINST
                        THE COMPANY ON AN INDIVIDUAL BASIS IN BINDING AND
                        CONFIDENTIAL ARBITRATION, AS SET FORTH IN THIS
                        ARBITRATION AGREEMENT.
                      </u>
                    </strong>
                  </p>
                  <p>
                    Any dispute, claim, or controversy arising out of or
                    relating to this Agreement or the breach, termination,
                    enforcement, interpretation, or validity thereof, including
                    the determination of the scope or applicability of this
                    agreement to arbitrate, shall be determined by final,
                    binding, and confidential arbitration in Iowa before one
                    arbitrator. The arbitration shall be administered by JAMS
                    pursuant to its Comprehensive Arbitration Rules and
                    Procedures. Judgment on the Award may be entered in any
                    court having jurisdiction. This clause shall not preclude
                    parties from seeking provisional remedies in aid of
                    arbitration from a court of appropriate jurisdiction.
                  </p>

                  <p>
                    <strong>
                      <u>Limitation on Time to File Claims</u>
                    </strong>
                  </p>
                  <p>
                    ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
                    RELATING TO THESE TERMS OR THE WEBSITE MUST BE COMMENCED
                    WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES;
                    OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY
                    BARRED.
                  </p>

                  <p>
                    <strong>
                      <u>Waiver and Severability</u>
                    </strong>
                  </p>

                  <p>
                    No waiver by the Company of any term or condition set out in
                    these Terms shall be deemed a further or continuing waiver
                    of such term or condition or a waiver of any other term or
                    condition, and any failure of the Company to assert a right
                    or provision under these Terms shall not constitute a waiver
                    of such right or provision.
                  </p>
                  <p>
                    If any provision of these Terms is held by a court or other
                    tribunal of competent jurisdiction to be invalid, illegal,
                    or unenforceable for any reason, such provision shall be
                    eliminated or limited to the minimum extent such that the
                    remaining provisions of the Terms will continue in full
                    force and effect.
                  </p>

                  <p>
                    <strong>
                      <u>Entire Agreement</u>
                    </strong>
                  </p>
                  <p>
                    These Terms constitute the sole and entire agreement between
                    you and the Company regarding the Website and supersede all
                    prior and contemporaneous understandings, agreements,
                    representations, and warranties, both written and oral,
                    regarding the Website.
                  </p>

                  <p>
                    <strong>
                      <u>Your Comments and Concerns</u>
                    </strong>
                  </p>
                  <p>
                    This Website is operated by: Advanced Rabbit Solutions Inc.
                  </p>

                  <p>
                    All other feedback, comments, requests for technical
                    support, and other communications relating to the Website
                    should be directed to::
                    <a href='http://www.Easy2Show.com'>
                      http://www.Easy2Show.com
                    </a>
                  </p>
                </div>

                <div className='col-md-12 col-lg-12 col-xs-12 terms'>
                  <div className='col-md-12 col-lg-12 col-xs-12 text-center mt-3'>
                    <h3 className='top-title mt-5'>
                      <span> Privacy Policy</span>
                    </h3>
                    <p className='term-date mb-4'>
                      <i>Last updated: August 26, 2024 </i>
                    </p>
                  </div>

                  <p>
                    This Privacy Policy describes Our policies and procedures on
                    the collection, use and disclosure of Your information when
                    You use the Service and tells You about Your privacy rights
                    and how the law protects You.
                  </p>
                  <p>
                    We use Your Personal data to provide and improve the
                    Service. By using the Service, You agree to the collection
                    and use of information in accordance with this Privacy
                    Policy. This Privacy Policy has been created with the help
                    of the Privacy Policy Generator.
                  </p>

                  <p>
                    <strong>
                      <u>Interpretation and Definitions</u>
                    </strong>
                  </p>
                  <p>
                    <strong>Interpretation</strong>
                  </p>
                  <p>
                    The words of which the initial letter is capitalized have
                    meanings defined under the following conditions. The
                    following definitions shall have the same meaning regardless
                    of whether they appear in singular or in plural.
                  </p>
                  <p>
                    <strong>Definitions</strong>
                  </p>
                  <p>For the purposes of this Privacy Policy:</p>

                  <p>
                    <strong>Account </strong> means a unique account created for
                    You to access our Service or parts of our Service.
                  </p>

                  <p>
                    <strong>Affiliate </strong> means an entity that controls,
                    is controlled by or is under common control with a party,
                    where "control" means ownership of 50% or more of the
                    shares, equity interest or other securities entitled to vote
                    for election of directors or other managing authority.
                  </p>

                  <p>
                    <strong>Company </strong> (referred to as either "the
                    Company", "We", "Us" or "Our" in this Agreement) refers to
                    Advanced Rabbit Solutions, LLC (DBA Easy2Show), PO Box 491,
                    Sheffield, IA 50475.
                  </p>

                  <p>
                    <strong>Cookies </strong> are small files that are placed on
                    Your computer, mobile device or any other device by a
                    website, containing the details of Your browsing history on
                    that website among its many uses.
                  </p>

                  <p>
                    <strong>Country </strong> refers to: Iowa, United States
                  </p>
                  <p>
                    <strong>Device </strong> means any device that can access
                    the Service such as a computer, a cellphone or a digital
                    tablet.
                  </p>
                  <p>
                    <strong>Personal Data </strong> is any information that
                    relates to an identified or identifiable individual. Service
                    refers to the Website.
                  </p>
                  <p>
                    <strong>Service Provider </strong> means any natural or
                    legal person who processes the data on behalf of the
                    Company. It refers to third-party companies or individuals
                    employed by the Company to facilitate the Service, to
                    provide the Service on behalf of the Company, to perform
                    services related to the Service or to assist the Company in
                    analyzing how the Service is used.
                  </p>
                  <p>
                    <strong>Usage Data </strong> refers to data collected
                    automatically, either generated by the use of the Service or
                    from the Service infrastructure itself (for example, the
                    duration of a page visit).{' '}
                  </p>
                  <p>
                    <strong>Website </strong> refers to Easy2Show, accessible
                    from{' '}
                    <a href='http://www.Easy2Show.com' target='_blank'>
                      http://www.Easy2Show.com
                    </a>{' '}
                  </p>
                  <p>
                    <strong>You </strong> means the individual accessing or
                    using the Service, or the company, or other legal entity on
                    behalf of which such individual is accessing or using the
                    Service, as applicable.
                  </p>

                  <p>
                    <strong>
                      <u>Collecting and Using Your Personal Data</u>
                    </strong>
                  </p>

                  <p>
                    <strong>Types of Data Collected</strong>
                  </p>

                  <p>
                    <strong>Personal Data</strong>
                  </p>
                  <p>
                    While using Our Service, We may ask You to provide Us with
                    certain personally identifiable information that can be used
                    to contact or identify You. Personally identifiable
                    information may include, but is not limited to:
                  </p>

                  <p>
                    Email address <br />
                    First name and last name <br />
                    Phone number
                    <br />
                    Address, State, Province, ZIP/Postal code, City
                    <br />
                    Usage Data
                  </p>

                  <p>
                    <strong>Usage Data</strong>
                  </p>

                  <p>
                    Usage Data is collected automatically when using the
                    Service.
                  </p>

                  <p>
                    Usage Data may include information such as Your Device's
                    Internet Protocol address (e.g. IP address), browser type,
                    browser version, the pages of our Service that You visit,
                    the time and date of Your visit, the time spent on those
                    pages, unique device identifiers and other diagnostic data.
                  </p>

                  <p>
                    When You access the Service by or through a mobile device,
                    We may collect certain information automatically, including,
                    but not limited to, the type of mobile device You use, Your
                    mobile device unique ID, the IP address of Your mobile
                    device, Your mobile operating system, the type of mobile
                  </p>

                  <p>
                    Internet browser You use, unique device identifiers and
                    other diagnostic data. We may also collect information that
                    Your browser sends whenever You visit our Service or when
                    You access the Service by or through a mobile device.
                  </p>

                  <p>
                    <strong>Tracking Technologies and Cookies</strong>
                  </p>

                  <p>
                    {' '}
                    We use Cookies and similar tracking technologies to track
                    the activity on Our Service and store certain information.
                    Tracking technologies used are beacons, tags, and scripts to
                    collect and track information and to improve and analyze Our
                    Service. The technologies We use may include:
                  </p>

                  <ul>
                    <li>
                      <strong>Cookies or Browser Cookies. </strong> A cookie is
                      a small file placed on Your Device. You can instruct Your
                      browser to refuse all Cookies or to indicate when a Cookie
                      is being sent. However, if You do not accept Cookies, You
                      may not be able to use some parts of our Service. Unless
                      you have adjusted Your browser setting so that it will
                      refuse Cookies, our Service may use Cookies.
                    </li>

                    <li>
                      <strong>Web Beacons. </strong>Certain sections of our
                      Service and our emails may contain small electronic files
                      known as web beacons (also referred to as clear gifs,
                      pixel tags, and single-pixel gifs) that permit the
                      Company, for example, to count users who have visited
                      those pages or opened an email and for other related
                      website statistics (for example, recording the popularity
                      of a certain section and verifying system and server
                      integrity).
                    </li>
                  </ul>

                  <p>
                    Cookies can be "Persistent" or "Session" Cookies. Persistent
                    Cookies remain on Your personal computer or mobile device
                    when You go offline, while Session Cookies are deleted as
                    soon as You close Your web browser. Learn more about cookies
                    on the Privacy Policies website article.
                  </p>

                  <p>
                    We use both Session and Persistent Cookies for the purposes
                    set out below:
                  </p>

                  <ul>
                    <li>
                      <strong> Necessary / Essential Cookies</strong> <br />
                      Type: Session Cookies <br />
                      Administered by: Us
                      <br />
                      Purpose: These Cookies are essential to provide You with
                      services available through the Website and to enable You
                      to use some of its features. They help to authenticate
                      users and prevent fraudulent use of user accounts. Without
                      these Cookies, the services that You have asked for cannot
                      be provided, and We only use these Cookies to provide You
                      with those services.
                    </li>
                    <li>
                      <strong>
                        Cookies Policy / Notice Acceptance Cookies
                      </strong>
                      <br />
                      Type: Persistent Cookies
                      <br />
                      Administered by: Us
                      <br />
                      Purpose: These Cookies identify if users have accepted the
                      use of cookies on the Website.
                    </li>
                    <li>
                      <strong>Functionality Cookies</strong>
                      <br />
                      Type: Persistent Cookies
                      <br />
                      Administered by: Us
                      <br />
                      Purpose: These Cookies allow us to remember choices You
                      make when You use the Website, such as remembering your
                      login details or language preference. The purpose of these
                      Cookies is to provide You with a more personal experience
                      and to avoid You having to re-enter your preferences every
                      time You use the Website.
                    </li>
                  </ul>

                  <p>
                    For more information about the cookies we use and your
                    choices regarding cookies, please visit our Cookies Policy
                    or the Cookies section of our Privacy Policy.
                  </p>

                  <p>
                    <strong>
                      <u>Use of Your Personal Data</u>
                    </strong>
                  </p>
                  <p>
                    The Company may use Personal Data for the following
                    purposes:
                  </p>

                  <ul>
                    <li>
                      <strong>To provide and maintain our Service,</strong>{' '}
                      including to monitor the usage of our Service.
                    </li>
                    <li>
                      To manage Your Account: to manage Your registration as a
                      user of the Service. The Personal Data You provide can
                      give You access to different functionalities of the
                      Service that are available to You as a registered user.
                    </li>
                    <li>
                      <strong>For the performance of a contract: </strong> the
                      development, compliance and undertaking of the purchase
                      contract for the products, items or services You have
                      purchased or of any other contract with Us through the
                      Service.
                    </li>
                    <li>
                      <strong>To contact You: </strong> To contact You by email,
                      telephone calls, SMS, or other equivalent forms of
                      electronic communication, such as a mobile application's
                      push notifications regarding updates or informative
                      communications related to the functionalities, products or
                      contracted services, including the security updates, when
                      necessary or reasonable for their implementation.
                    </li>

                    <li>
                      <strong>To provide You with news, </strong> special offers
                      and general information about other goods, services and
                      events which we offer that are similar to those that you
                      have already purchased or enquired about unless You have
                      opted not to receive such information.
                    </li>
                    <li>
                      <strong>To manage Your requests:</strong> To attend and
                      manage Your requests to Us.
                    </li>
                    <li>
                      <strong>For business transfers: </strong>We may use Your
                      information to evaluate or conduct a merger, divestiture,
                      restructuring, reorganization, dissolution, or other sale
                      or transfer of some or all of Our assets, whether as a
                      going concern or as part of bankruptcy, liquidation, or
                      similar proceeding, in which Personal Data held by Us
                      about our Service users is among the assets transferred.
                    </li>
                    <li>
                      <strong>For other purposes: </strong> We may use Your
                      information for other purposes, such as data analysis,
                      identifying usage trends, determining the effectiveness of
                      our promotional campaigns and to evaluate and improve our
                      Service, products, services, marketing and your
                      experience.
                    </li>
                  </ul>

                  <p>
                    <strong>
                      <u>
                        We may share Your personal information in the following
                        situations:
                      </u>
                    </strong>
                  </p>

                  <ul>
                    <li>
                      {' '}
                      <strong>With Service Providers: </strong> We may share
                      Your personal information with Service Providers to
                      monitor and analyze the use of our Service, to contact
                      You.
                    </li>
                    <li>
                      {' '}
                      <strong>For business transfers: </strong> We may share or
                      transfer Your personal information in connection with, or
                      during negotiations of, any merger, sale of Company
                      assets, financing, or acquisition of all or a portion of
                      Our business to another company.
                    </li>
                    <li>
                      {' '}
                      <strong>With Affiliates: </strong> We may share Your
                      information with Our affiliates, in which case we will
                      require those affiliates to honor this Privacy Policy.
                      Affiliates include Our parent company and any other
                      subsidiaries, joint venture partners or other companies
                      that We control or that are under common control with Us.
                    </li>
                    <li>
                      {' '}
                      <strong>With business partners: </strong> We may share
                      Your information with Our business partners to offer You
                      certain products, services or promotions.
                    </li>
                    <li>
                      {' '}
                      <strong>With other users: </strong> when You share
                      personal information or otherwise interact in the public
                      areas with other users, such information may be viewed by
                      all users and may be publicly distributed outside.
                    </li>
                    <li>
                      {' '}
                      <strong> With Your consent: </strong> We may disclose Your
                      personal information for any other purpose with Your
                      consent.
                    </li>
                  </ul>

                  <p>
                    <strong>
                      <u>Retention of Your Personal Data</u>
                    </strong>
                  </p>

                  <p>
                    The Company will retain Your Personal Data only for as long
                    as is necessary for the purposes set out in this Privacy
                    Policy. We will retain and use Your Personal Data to the
                    extent necessary to comply with our legal obligations (for
                    example, if we are required to retain your data to comply
                    with applicable laws), resolve disputes, and enforce our
                    legal agreements and policies.
                  </p>
                  <p>
                    The Company will also retain Usage Data for internal
                    analysis purposes. Usage Data is generally retained for a
                    shorter period of time, except when this data is used to
                    strengthen the security or to improve the functionality of
                    Our Service, or We are legally obligated to retain this data
                    for longer time periods.
                  </p>

                  <p>
                    <strong>
                      <u>Transfer of Your Personal Data</u>
                    </strong>
                  </p>

                  <p>
                    Your information, including Personal Data, is processed at
                    the Company's operating offices and in any other places
                    where the parties involved in the processing are located. It
                    means that this information may be transferred to — and
                    maintained on — computers located outside of Your state,
                    province, country or other governmental jurisdiction where
                    the data protection laws may differ than those from Your
                    jurisdiction.
                  </p>

                  <p>
                    Your consent to this Privacy Policy followed by Your
                    submission of such information represents Your agreement to
                    that transfer.
                  </p>

                  <p>
                    The Company will take all steps reasonably necessary to
                    ensure that Your data is treated securely and in accordance
                    with this Privacy Policy and no transfer of Your Personal
                    Data will take place to an organization or a country unless
                    there are adequate controls in place including the security
                    of Your data and other personal information.
                  </p>

                  <p>
                    <strong>
                      <u>Delete Your Personal Data</u>
                    </strong>
                  </p>
                  <p>
                    You have the right to delete or request that We assist in
                    deleting the Personal Data that We have collected about You.
                  </p>
                  <p>
                    Our Service may give You the ability to delete certain
                    information about You from within the Service.
                  </p>
                  <p>
                    You may update or amend Your information at any time by
                    signing in to Your Account, if you have one, and visiting
                    the account settings section that allows you to manage Your
                    personal information. You may also contact Us to request
                    access to, or to request Your us to inactivate Your account
                    and if possible, delete any personal information that You
                    have provided to Us.
                  </p>
                  <p>
                    Please note, however, that We may need to retain certain
                    information when we have a legal obligation or lawful basis
                    to do so or in the case that it is needed to maintain
                    reports or databases in competitions, events or activities
                    that You participated in hosted by our software to maintain
                    the integrity of such reports, databases and/or documents.
                  </p>

                  <p>
                    <strong>
                      <u>Disclosure of Your Personal Data</u>
                    </strong>
                  </p>

                  <p>
                    <strong>Business Transactions</strong> <br />
                    If the Company is involved in a merger, acquisition or asset
                    sale, Your Personal Data may be transferred. We will provide
                    notice before Your Personal Data is transferred and becomes
                    subject to a different Privacy Policy.
                  </p>

                  <p>
                    <strong>Law enforcement</strong> <br />
                    Under certain circumstances, the Company may be required to
                    disclose Your Personal Data if required to do so by law or
                    in response to valid requests by public authorities (e.g. a
                    court or a government agency).
                  </p>

                  <p>
                    <strong>Other legal requirements</strong> <br />
                    The Company may disclose Your Personal Data in the good
                    faith belief that such action is necessary to:
                  </p>
                  <ul>
                    <li>Comply with a legal obligation</li>
                    <li>
                      Protect and defend the rights or property of the Company
                    </li>
                    <li>
                      Prevent or investigate possible wrongdoing in connection
                      with the Service
                    </li>
                    <li>
                      Protect the personal safety of Users of the Service or the
                      public
                    </li>
                    <li>Protect against legal liability</li>
                  </ul>

                  <p>
                    <strong>
                      <u>Security of Your Personal Data</u>
                    </strong>
                  </p>
                  <p>
                    The security of Your Personal Data is important to Us, but
                    remember that no method of transmission over the Internet,
                    or method of electronic storage is 100% secure. While We
                    strive to use commercially acceptable means to protect Your
                    Personal Data, We cannot guarantee its absolute security.
                  </p>

                  <p>
                    <strong>Children's Privacy</strong>
                  </p>
                  <p>
                    Our Service does not address anyone under the age of 13. We
                    do not knowingly collect personally identifiable information
                    from anyone under the age of 13. If You are a parent or
                    guardian and You are aware that Your child has provided Us
                    with Personal Data, please contact Us. If We become aware
                    that We have collected Personal Data from anyone under the
                    age of 13 without verification of parental consent, we take
                    steps to remove that information from Our servers.
                  </p>
                  <p>
                    If We need to rely on consent as a legal basis for
                    processing Your information and Your country requires
                    consent from a parent, we may require Your parent's consent
                    before We collect and use that information.
                  </p>

                  <p>
                    <strong>
                      <u>Links to Other Websites</u>
                    </strong>
                  </p>
                  <p>
                    Our Service may contain links to other websites that are not
                    operated by Us. If You click on a third party link, You will
                    be directed to that third party's site. We strongly advise
                    You to review the Privacy Policy of every site You visit.
                  </p>

                  <p>
                    We have no control over and assume no responsibility for the
                    content, privacy policies or practices of any third-party
                    sites or services.
                  </p>

                  <p>
                    <strong>
                      <u>Changes to this Privacy Policy</u>
                    </strong>
                  </p>
                  <p>
                    We may update Our Privacy Policy from time to time. Each
                    time you login into Our site You will be asked to agree that
                    you have reviewed the most current Terms and Conditions and
                    Privacy Policy. Please review each time you log in to use
                    Our site.
                  </p>

                  <p>
                    <strong>
                      <u>Contact Us</u>
                    </strong>
                  </p>

                  <p>
                    If you have any questions about this Privacy Policy, You can
                    contact us:
                  </p>

                  <p>
                    By email:{' '}
                    <a href='mailto:support@Easy2Show.com'>
                      support@Easy2Show.com
                    </a>{' '}
                    <br />
                    By visiting this page on our website:{' '}
                    <a
                      href='https://www.easy2show.com/contact.php'
                      target='_blank'
                    >
                      https://www.easy2show.com/contact.php
                    </a>{' '}
                  </p>

                  <p>
                    <strong>
                      <u>
                        {' '}
                        If You do not agree to Our Terms and Conditions and/or
                        Our Privacy Policy;
                      </u>
                    </strong>
                  </p>

                  <p>
                    You will need to contact the show secretary of any
                    competition that uses Our software and that secretary will
                    if they choose, no guarantee that they will and we cannot
                    mandate to that secretary that they have to, take your entry
                    outside of our platform and put your entry in using a blind
                    account that will still need to collect your name, address,
                    city, state, zip, phone number and email address in order to
                    complete your entry.  But You will not have a user profile
                    or online access.
                  </p>
                </div>
              </Col>
            </Row>
            <div className='mt-2 col-md-12 col-sm-12 col-lg-12 text-center'>
              <Button
                onClick={() => {
                  setShowModal(false)
                  setInputs((prev) => ({
                    ...prev,
                    termsAndConditions: true,
                  }))
                }}
              >
                OKAY
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  )
}
