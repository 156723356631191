import {
  CURRENT,
  PAGE_CHANGE,
  PaGINATION_DATA,
  RESET_PAGE,
} from './actionTypes'

const initialState = {
  currentPage: 1,
  perPage: 10,
  page: 1,
  totalPage: 1,
  totalRecords: 1,
  loading: true,
}

const pagination = (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    case CURRENT:
      state = { ...state, currentPage: action.payload }
      break
    case PaGINATION_DATA:
      return {
        ...state,
        parPage: payload.perPage,
        page: payload?.page || 1,
        totalPage: payload?.totalPage || 1,
        totalRecords: payload?.totalRecords || 1,
        loading: false,
      }
    case RESET_PAGE:
      return {
        ...state,
        page: 1,
        totalPage: 1,
        totalRecords: 1,
        loading: true,
      }
    case PAGE_CHANGE:
      return {
        ...state,
        page: payload || 1,
      }
    default:
      state = { ...state }
      break
  }
  return state
}
export default pagination
