import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import { UrlEncodeHook } from '../../hooks'
import { get, post } from '../../helpers/api_helper'
import _ from 'lodash'
import { allClassTypeList } from '../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import * as validation from '../../helpers/validation'
import * as Util from '../../helpers/utils'
import { toast } from 'react-toastify'
import GRecaptcha from '../../components/GRecaptcha'

const AnimalContact = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { urlDecode } = UrlEncodeHook()
  let { ID } = useParams()
  ID = urlDecode(ID)
  const [userDetail, setUserDetail] = useState({})
  const [breedData, setbreedData] = useState({})

  //
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [description, setDescription] = useState('')
  const [error, setError] = useState({})
  const [GRC, setGRC] = useState(null)
  //
  const { ClassTypeList } = useSelector((state) => state.ClassTypes)

  useEffect(() => {
    get(
      `/api/v1/easyfied/animal-listing/user/one/${ID}?with_owner_details=1`
    ).then((res) => {
      setUserDetail(res.data)
      if (res.data?.breed_id) {
        get(`/api/v1/breed-setup/class/one/${res.data?.breed_id}`).then(
          (res) => {
            setbreedData(_.first(res.data))
          }
        )
      }
    })
    dispatch(allClassTypeList())
  }, [])

  const onSubmit = async () => {
    const token = await GRC.current.executeAsync()

    let validationFlag = false
    let err = {}
    if (!name) {
      validationFlag = true
      err.name = `Name ${validation.REQUIRED}`
    }
    if (name) {
      if (!Util.ONLY_CHAR_REGEX.test(name.trim())) {
        validationFlag = true
        err.name = `Name ${validation.ONLY_CHARACTER_AND_HYPHEN}`
      } else if (name.length >= 50) {
        validationFlag = true
        err.name = `Name ${validation.MAX_CHAR(50)}`
      }
    }
    if (!email) {
      validationFlag = true
      err.email = `Email ${validation.REQUIRED}`
    }
    if (email) {
      if (!Util.EMAIL_REGEX.test(email)) {
        validationFlag = true
        err.email = `Email ${validation.INVALID}`
      }
    }
    if (!description) {
      validationFlag = true
      err.description = `Message ${validation.REQUIRED}`
    }
    if (description) {
      if (description.length >= 1000) {
        validationFlag = true
        err.description = `Message ${validation.MAX_CHAR_FUN(1000)}`
      }
    }
    setError(err)
    if (!validationFlag && token) {
      let obj = {
        animal_listing_id: ID,
        req_user_name: name,
        req_user_email: email,
        text_description: description,
        breed_name: breedData?.is_published ? breedData?.breed_name : '',
        user_id: userDetail?.owner_details?.id,
        amount: userDetail?.amount,
        category_name: _.get(
          _.find(
            Category,
            (q) => q.breed_category_id === userDetail?.category_id
          ),
          'category_name'
        ),
        class_type_name: _.get(
          _.find(ClassTypeList, (q) => q.class_id === userDetail.class_type_id),
          'class_name'
        ),
      }
      document.getElementById('submit-btn').setAttribute('disabled', true)
      post(`/api/v1/easyfied/animal-listing/user/create-contact-us`, obj)
        .then((res) => {
          // document.getElementById('submit-btn').removeAttribute('disabled')
          if (res) {
            setName('')
            setEmail('')
            setDescription('')
            toast.success(res)
            history.push('/easyfides')
          }
        })
        .catch((err) => {
          document.getElementById('submit-btn').removeAttribute('disabled')
          if (err?.response?.data) {
            toast.error(err?.response?.data)
          }
        })
    }
  }

  return (
    <section
      className='section bg-gray-101 pages event-list mt-12'
      style={{ minHeight: '100vh' }}
    >
      <Container fluid>
        <div className='row mb-6'>
          <Col md={6} sm={6} xs={6}>
            <h2 className='top-title'>
              <span>Contact</span>
            </h2>
          </Col>
          <Col md={6} sm={6} xs={6} className='text-end'>
            <Link to='/easyfides/listing' title='Back'>
              <Button color='primary'>BACK</Button>
            </Link>
          </Col>
        </div>
        <div className='col-md-12 mt-3'>
          <p>
            Easyfieds will send your request to the listing person and that
            person will respond directly to you at the email listed below. If
            the listing person’s contact information is visible, you may choose
            to contact them through the Easyfied contact request or directly.
          </p>
        </div>
        <Row>
          <Col
            md={12}
            xs={12}
            sm={12}
            className='d-flex1 justify-content-center form-cont-label'
          >
            <Row>
              <Col md={12} xs={12} sm={12}>
                <Card>
                  <CardBody>
                    <div className='p-6'>
                      <div className='pb-3'>
                        <h3 className='mb-3'>
                          Rabbits & Cavies - Contact Request
                        </h3>
                      </div>

                      <Row>
                        <Col md={12} sm={12} xs={12} className='m-auto'>
                          <Row>
                            <Col md={12} sm={12} className='mb-3'>
                              <Label>Please enter your information</Label>
                            </Col>
                            <Col md={12} sm={12}>
                              <FormGroup className='mb-2'>
                                <Label htmlFor='name'>Name *</Label>
                                <Input
                                  type='text'
                                  name='name'
                                  id='name'
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                  placeholder='Name'
                                />
                                {error?.name ? (
                                  <div className='text-danger mt-1'>
                                    {error?.name}
                                  </div>
                                ) : null}
                              </FormGroup>
                              <FormGroup className='mb-2'>
                                <Label htmlFor='email'>Email *</Label>
                                <Input
                                  type='email'
                                  name='email'
                                  id='email'
                                  value={email}
                                  placeholder='Email'
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                                {error?.email ? (
                                  <div className='text-danger mt-1'>
                                    {error?.email}
                                  </div>
                                ) : null}
                              </FormGroup>
                            </Col>

                            <FormGroup className='my-3'>
                              <Label htmlFor='Description' className='d-block'>
                                Message *
                              </Label>
                              <Input
                                id='Description'
                                name='Description'
                                value={description}
                                placeholder='Message'
                                type='textarea'
                                rows='4'
                                onChange={(e) => setDescription(e.target.value)}
                              />
                              <div className='text-muted'>{`(Note: Do not use <, >, ', ")`}</div>
                              {error?.description ? (
                                <div className='text-danger mt-1'>
                                  {error?.description}
                                </div>
                              ) : null}
                            </FormGroup>

                            {/* <Col md={12} xs={12} className='mb-2 mt-3'>
                              <p className='text-black'>
                                {`I am interested in > ${_.get(
                                  _.find(
                                    Category,
                                    (q) =>
                                      q?.breed_category_id ===
                                      userDetail?.category_id
                                  ),
                                  'category_name',
                                  '-'
                                )}`}
                                {' > '}
                                {breedData?.is_published ? (
                                  breedData?.breed_name || '-'
                                ) : (
                                  <span className='text-danger'>
                                    Breed Deleted
                                  </span>
                                )}
                                {userDetail?.class_type_id ? (
                                  <>
                                    {' > '}
                                    {_.get(
                                      _.find(
                                        ClassTypeList,
                                        (q) =>
                                          q?.class_id ===
                                          userDetail.class_type_id
                                      ),
                                      'class_name',
                                      ''
                                    ) ? (
                                      _.get(
                                        _.find(
                                          ClassTypeList,
                                          (q) =>
                                            q.class_id ===
                                            userDetail.class_type_id
                                        ),
                                        'class_name',
                                        ''
                                      )
                                    ) : (
                                      <span className='text-danger'>
                                        Class Deleted
                                      </span>
                                    )}
                                  </>
                                ) : null}
                              </p>
                            </Col> */}
                            {userDetail?.owner_details?.alias_name_easyfied &&
                            userDetail?.owner_details?.email ? (
                              <div className='col-md-12 col-xs-12'>
                                <p className='text-black'>
                                  <strong>Name : </strong>
                                  <span>
                                    {
                                      userDetail?.owner_details
                                        ?.alias_name_easyfied
                                    }
                                  </span>
                                </p>{' '}
                                <p className='text-black'>
                                  <strong>Email : </strong>
                                  <span>
                                    {' '}
                                    <a
                                      href={`mailto:${userDetail?.owner_details?.email}`}
                                    >
                                      {userDetail?.owner_details?.email}
                                    </a>{' '}
                                  </span>
                                </p>
                              </div>
                            ) : null}

                            <div
                              className='col-md-12 pt-2 text-left mt-4'
                              title='Submit'
                            >
                              <Button
                                size='lg'
                                id='submit-btn'
                                // disabled={
                                //   _.get(
                                //     _.find(
                                //       ClassTypeList,
                                //       (q) =>
                                //         q.class_id === userDetail.class_type_id
                                //     ),
                                //     'class_name',
                                //     ''
                                //   )
                                //     ? false
                                //     : true
                                // }
                                onClick={onSubmit}
                              >
                                SUBMIT
                              </Button>
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <GRecaptcha setGRC={setGRC} />
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AnimalContact

let Category = [
  {
    breed_category_id: 1,
    category_name: 'Rabbit Breed List',
  },
  {
    breed_category_id: 2,
    category_name: 'Cavy Breed List',
  },
  {
    breed_category_id: 3,
    category_name: 'Exhibition Breed List',
  },
  {
    breed_category_id: 4,
    category_name: 'Commercial Breed List',
  },
]
