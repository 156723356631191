import { LISTING_CATEGORY, LISTING_CATEGORY_SUCCESS } from './actionType'

//

const INIT_STATE = {
  categoryList: [],
  loading: true,
  error: {},
}

const EasyFiedsCategoryList = (state = INIT_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case LISTING_CATEGORY:
      return {
        ...state,
        categoryList: [],
        loading: true,
      }
    case LISTING_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryList: payload,
        loading: false,
      }
    default:
      return state
  }
}

export default EasyFiedsCategoryList
