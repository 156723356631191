import {
  GET_ALL_SUB_CATEGORY,
  GET_ALL_SUB_CATEGORY_SUCCESS,
  GET_ALL_VENDOR_CATEGORY_SUCCESS,
} from './actionType'

const INIT_STATE = {
  categoryList: [],
  subCategoryList: [],
  subCategoryLoading: true,
  loading: true,
  error: {},
}

const VendorCategoryReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_ALL_VENDOR_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryList: payload,
        loading: false,
      }

    case GET_ALL_SUB_CATEGORY:
      return {
        ...state,
        subCategoryList:[],
        subCategoryLoading: true,
      }
    case GET_ALL_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        subCategoryList: payload,
        subCategoryLoading: false,
      }
    default:
      return state
  }
}

export default VendorCategoryReducer
