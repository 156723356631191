import { put, takeEvery } from 'redux-saga/effects'

import {
  ADD_EQUIPMENT,
  ALL_MEDIA_LIST_EQUIPMENT,
  ALL_MEDIA_LIST_SUCCESS_EQUIPMENT,
  DELETE_EQUIPMENT,
  DELETE_MEDIA_EQUIPMENT,
  DELETE_MEDIA_SUCCESS_EQUIPMENT,
  LISTING_EQUIPMENT,
  LISTING_SUCCESS_EQUIPMENT,
  SAVE_MEDIA_EQUIPMENT,
  UPDATE_MEDIA_EQUIPMENT,
  UPDATE_EQUIPMENT,
  LISTING_EQUIPMENT_FRONT,
} from './actionType'
import { post, get, del, PUT, PATCH } from '../../../helpers/api_helper'
import { toast } from 'react-toastify'

import { PaGINATION_DATA } from '../../pagination/actionTypes'

function* addEquipmentSagas({ payload }) {
  try {
    const res = yield post(
      `/api/v1/easyfied/equipment-product/create`,
      payload?.data
    )
    if (res) {
      toast.success(res)
    }
    setTimeout(() => {
      if (payload.path) {
        payload.history.push(payload.path)
      } else {
        payload.history.push('/easyfides/profile/equipments')
      }
    }, 1500)
  } catch (err) {
    document.getElementById('submit-btn').removeAttribute('disabled')
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* EquipmentListingSaga({ payload }) {
  try {
    const res = yield get(`/api/v1/easyfied/equipment-product/all`, {
      params: payload,
    })

    yield put({
      type: LISTING_SUCCESS_EQUIPMENT,
      payload: res?.data || [],
    })
    if (payload?._limit) {
      const limit = payload._limit || 10
      const TotalPage = Math.ceil(res.totalRecords / limit) || 1
      const paginationObj = {
        page: payload._page,
        totalPage: TotalPage,
        totalRecords: res?.totalRecords,
      }
      yield put({
        type: PaGINATION_DATA,
        payload: paginationObj,
      })
    }
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}
function* EquipmentListingFrontSaga({ payload }) {
  try {
    const res = yield get(
      `/api/v1/easyfied/equipment-product/all-listing?_page=${
        payload?._page || ''
      }&_limit=${payload?._limit || ''}&_sort=${payload?._sort || ''}&_order=${
        payload?._order || ''
      }&product_name=${payload?.product_name || ''}&equipment_from=${
        payload?.equipment_from || ''
      }&equipment_product_type=${
        payload?.equipment_product_type || ''
      }&category_id=${payload?.category_id || ''}`
    )

    yield put({
      type: LISTING_SUCCESS_EQUIPMENT,
      payload: res?.data || [],
    })
    if (payload?._limit) {
      const limit = payload._limit || 10
      const TotalPage = Math.ceil(res.totalRecords / limit) || 1
      const paginationObj = {
        page: payload._page,
        totalPage: TotalPage,
        totalRecords: res?.totalRecords,
      }
      yield put({
        type: PaGINATION_DATA,
        payload: paginationObj,
      })
    }
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* EquipmentDeleteSaga({ payload }) {
  try {
    const res = yield del(
      `/api/v1/easyfied/equipment-product/delete/${payload?.ID}`
    )
    if (res) {
      toast.success(res)
      payload.setReload(!payload.reload)
    }
  } catch (err) {
    if (err?.response?.data) {
      document.getElementById('submit-btn').removeAttribute('disabled')
      toast.error(err?.response?.data)
    }
  }
}

function* EquipmentUpdateSaga({ payload }) {
  try {
    const res = yield PUT(
      `/api/v1/easyfied/equipment-product/update/${payload?.ID}`,
      payload?.data
    )
    if (res) {
      toast.success(res)
    }
    setTimeout(() => {
      setTimeout(() => {
        if (payload.path) {
          payload.history.push(payload.path)
        } else {
          payload.history.push('/easyfides/profile/equipments')
        }
      }, 1500)
    }, 1500)
  } catch (err) {
    if (err?.response?.data) {
      document.getElementById('submit-btn').removeAttribute('disabled')
      toast.error(err?.response?.data)
    }
  }
}

function* saveMediaSaga({ payload }) {
  try {
    const res = yield post(
      `/api/v1/easyfied/equipment-product-doc/create?equipment_product_id=${payload?.params?.equipment_product_id}`,
      payload?.data
    )
    if (res.data) {
      payload.setReload(!payload.reload)
      toast.success(res.data)
    }
    document
      .getElementById(`submit-btn-${payload?.index}`)
      .removeAttribute('disabled')
  } catch (err) {
    if (err?.response?.data) {
      document
        .getElementById(`submit-btn-${payload?.index}`)
        .removeAttribute('disabled')
      toast.error(err?.response?.data)
    }
  }
}
function* updateMediaSaga({ payload }) {
  try {
    const res = yield PATCH(
      `/api/v1/easyfied/equipment-product-doc/update/${payload?.params?.ID}?equipment_product_id=${payload?.params?.equipment_product_id}`,
      payload?.data
    )
    if (res.data) {
      payload.setReload(!payload.reload)
      toast.success(res.data)
      document
        .getElementById(`submit-btn-${payload?.index}`)
        .removeAttribute('disabled')
    }
  } catch (err) {
    if (err?.response?.data) {
      document
        .getElementById(`submit-btn-${payload?.index}`)
        .removeAttribute('disabled')
      toast.error(err?.response?.data)
    }
  }
}
function* allEquipmentMediaSaga({ payload }) {
  try {
    const res = yield get(
      `/api/v1/easyfied/equipment-product-doc/all?equipment_product_id=${payload?.params?.equipment_product_id}&doc_type=${payload?.params?.doc_type}`
    )

    yield put({
      type: ALL_MEDIA_LIST_SUCCESS_EQUIPMENT,
      payload: res?.data || [],
    })
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* deleteEquipmentMediaSagas({ payload }) {
  try {
    if (payload?.ID) {
      const res = yield del(
        `/api/v1/easyfied/equipment-product-doc/delete/${payload?.ID}`,
        payload?.data
      )
      if (res.data) {
        toast.success(res.data)
      }
    }
    yield put({
      type: DELETE_MEDIA_SUCCESS_EQUIPMENT,
      payload,
    })
  } catch (err) {
    document.getElementById('submit-btn').removeAttribute('disabled')
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* EquipmentSaga() {
  yield takeEvery(ADD_EQUIPMENT, addEquipmentSagas)

  yield takeEvery(LISTING_EQUIPMENT, EquipmentListingSaga)
  yield takeEvery(LISTING_EQUIPMENT_FRONT, EquipmentListingFrontSaga)
  yield takeEvery(DELETE_EQUIPMENT, EquipmentDeleteSaga)
  yield takeEvery(UPDATE_EQUIPMENT, EquipmentUpdateSaga)
  yield takeEvery(SAVE_MEDIA_EQUIPMENT, saveMediaSaga)
  yield takeEvery(UPDATE_MEDIA_EQUIPMENT, updateMediaSaga)
  yield takeEvery(DELETE_MEDIA_EQUIPMENT, deleteEquipmentMediaSagas)
  yield takeEvery(ALL_MEDIA_LIST_EQUIPMENT, allEquipmentMediaSaga)
}

export default EquipmentSaga
