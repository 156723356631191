import React, { useEffect, useState } from 'react'
import {
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Card,
  Spinner,
  Table,
  Button,
} from 'reactstrap'

import _ from 'lodash'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'
import { UrlEncodeHook } from '../../hooks'
import { useDispatch, useSelector } from 'react-redux'
import { allStateList, productListing } from '../../store/actions'
import { get } from '../../helpers/api_helper'
import ImageModal from '../../components/ImageModal/ImageModal'
import { toast } from 'react-toastify'
import Pagination from '../Profile/Pagination/Pagination'

const Listing = () => {
  const { urlDecode, urlEncode } = UrlEncodeHook()

  const dispatch = useDispatch()

  let { categoryId, subcategoryId } = useParams()
  categoryId = urlDecode(categoryId)
  subcategoryId = urlDecode(subcategoryId)

  const { limit, ProductList, StateList, loading } = useSelector((state) => {
    return {
      limit: state.pagination.perPage,
      ProductList: state.EasyFiedsProduct.productList,
      loading: state.EasyFiedsProduct.loading,
      StateList: state.State.StateList,
    }
  })

  const [title, setTitle] = useState('')
  const [state_id, setState_id] = useState('')

  const [modal, setModal] = useState(false)
  const [imageList, setImageList] = useState([])

  useEffect(() => {
    dispatch(allStateList())
  }, [])

  useEffect(() => {
    let data = {
      params: {
        _limit: limit,
        _page: 1,
        category_id: categoryId,
        product_name: title,
        state_id: state_id,
        _sort: 'equipment_from,created_on',
        _order: 'ASC,desc',
      },
    }
    dispatch(productListing(data))
  }, [limit, categoryId,title,state_id])

  const showModal = async (id) => {
    try {
      const res = await get(
        `/api/v1/easyfied/equipment-product-doc/all?equipment_product_id=${id}&doc_type=img`
      )
      let finalData = res?.data || []
      finalData = _.orderBy(finalData, 'display_order')
      finalData = Array.isArray(finalData)
        ? finalData.map((x) => ({
            original: x.document_file,
            thumbnail: x.document_file,
            description: x.document_title,
          }))
        : []
      setImageList(finalData)
      setModal(true)
    } catch (err) {
      if (err?.response?.data) {
        toast.error(err?.response?.data)
      }
    }
  }

  const modalClose = () => {
    setModal(false)
    setImageList([])
  }

  const paginationClick = (nextPage) => {
      const { selected } = nextPage
      const finalPage = (Number(selected) || 0) + 1
      // let params = {
      //   _limit: perPage,
      //   _page: finalPage,
      //   category_id: category_id,
      //   _sort: 'equipment_from,created_on',
      //   _order: 'ASC,desc',
      // }
      // dispatch(listingEquipmentFront(params))
      let data = {
        params: {
          _limit: limit,
          _page: finalPage,
          category_id: categoryId,
          product_name: title,
          state_id: state_id,
          _sort: 'equipment_from,created_on',
          _order: 'ASC,desc',
        },
      }
      dispatch(productListing(data))
    }

  return (
    <section className='section bg-gray-101 pages event-list mt-12'>
      <Container fluid>
        <Row className='mb-2'>
          <div class='col-md-12 col-lg-12 col-xs-12 text-center'>
            <h2 className='top-title'>
              <span>{_.get(_.first(ProductList), 'category_name', '')}</span>
            </h2>
          </div>

          <Col lg='12' className='text-center mb-3 filter-line'>
            <Card body>
              <Row className=''>
                <Col md='3' className='mb-3'>
                  <FormGroup>
                    <Input
                      name='title'
                      type='text'
                      placeholder='Search By Title'
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                {/* <Col md='3' className='mb-3'>
                  <FormGroup>
                    <Input
                      name='country_id'
                      type='select'
                      className='form-select'
                    >
                      <option value={''}>Select Country</option>
                      <option value={'2'}>2</option>
                      <option value={'3'}>3</option>
                      <option value={'4'}>4</option>
                      <option value={'5'}>5</option>
                    </Input>
                  </FormGroup>
                </Col> */}
                <Col md='3' className='mb-3'>
                  <FormGroup>
                    <Input
                      name='state_id'
                      type='select'
                      className='form-select'
                      onChange={(e) => setState_id(e.target.value)}
                    >
                      <option value={''}>Select State</option>
                      {_.size(StateList)
                        ? StateList.map((state) => {
                            return (
                              <option value={state.state_id}>
                                {state.state_code}
                              </option>
                            )
                          })
                        : null}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md='12'>
                  <Table responsive hover1 className='table cust-tab table'>
                    <thead className='table-color'>
                      <tr>
                        <th>Posted Date</th>
                        <th>Title</th>
                        <th>Picture</th>
                        <th>State</th>
                        <th className='text-center' />
                      </tr>
                    </thead>
                    <tbody>
                      {_.size(ProductList) && !loading ? (
                        ProductList.map((product, index) => {
                          return (
                            <tr key={product.equipment_product_id}>
                              <td>
                                {moment(product.created_on).format('Do MMM YY')}
                              </td>
                              <td>{product.product_name}</td>
                              <td>
                                <ImageComponent
                                  ID={product?.equipment_product_id}
                                  showModal={showModal}
                                />
                              </td>
                              <td>{product.owner_state_code}</td>
                              <td>
                                {/* '/easyfides/:categoryId/:subcategoryId/listing/:productId', */}
                                <Link
                                  to={`/easyfides/${urlEncode(
                                    categoryId
                                  )}/${urlEncode(
                                    subcategoryId
                                  )}/listing/${urlEncode(
                                    product.equipment_product_id
                                  )}`}
                                >
                                  <Button size='sm'>More info</Button>
                                </Link>
                              </td>
                            </tr>
                          )
                        })
                      ) : !loading && !_.size(ProductList) ? (
                        <tr>
                          <td colSpan={4} className='text-center'>
                            No product currently found
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan={4}>
                            <Spinner />
                          </td>
                        </tr>
                        // <Col md={12} className='text-center'>
                        //   <Spinner color />
                        // </Col>
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <ImageModal
                modalClose={modalClose}
                modal={modal}
                imageList={imageList}
              />
               <Row>
            <Col lg={12} className='easy-page'>
              <Pagination paginationClick={paginationClick} />
            </Col>
          </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Listing

const ImageComponent = ({ ID, showModal }) => {
  const [breedData, setbreedData] = useState({})

  useEffect(() => {
    get(
      `/api/v1/easyfied/equipment-product-doc/all?equipment_product_id=${ID}&doc_type=img`
    ).then((res) => {
      let finalData = res.data
      finalData = _.first(_.orderBy(finalData, 'display_order'))
      setbreedData(finalData)
    })
  }, [ID])

  return breedData?.document_file ? (
    <div onClick={() => showModal(ID)} style={{ cursor: 'pointer' }}>
      <img
        src={breedData?.document_file}
        alt={breedData?.document_title || ''}
        style={{ width: '100px' }}
        className='img-thumbnail rounded'
        title={breedData?.document_title || ''}
      />
    </div>
  ) : (
    <img
      src={'/assets/img/logo/logo-hq.png'}
      alt='logo'
      // style={{ width: '100px' }}
      style={{ width: '100px' }}
      className='img-thumbnail rounded'
      title={'logo'}
    />
  )
}
